import React from "react";
// Customizable Area Start
import {
    Box,Button,
    Dialog, Typography, Grid, TableBody, TableCell, TableRow, TableHead, Table ,styled
} from "@material-ui/core";
import {
    withStyles,
} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
export const configJSON = require("./config");

import { lamsaLogo } from "../../CustomisableUserProfiles/src/assets";
import withCommonStyles from "../../../components/src/withCommonStyles.web";
import PdfController, {
} from "./PdfController.web";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { CONSTANTS } from "../../../components/src/theme";
import { CreateQuote } from "../../../components/src/CustomizableComponents.web";
import { t } from "i18next";
// Customizable Area End



// Customizable Area Start
  interface ProductItem {
    id: string;
    type: string;
    attributes: {
      product_id: number;
      service_id: null | number;
      total: string;
      unit_price: string;
      quantity: number;
      currency: null | string;
      job_id: number;
      description: string;
      visit_id: null | string;
      product_name: string;
      quote_id: null | string;
    };
  }
interface Classes {
    borderBottom: string
    invoiceOrderText: string
    textEnd: string
    textAlign: string
}
const StyledDialog = withStyles({
    root: {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1166px",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
        }
    }
})(Dialog);
export function style (language:string,classes:Classes){
    if(language === "ar"){
      return `${classes.borderBottom} ${classes.invoiceOrderText} ${classes.textEnd}`
    } else{
      return `${classes.borderBottom} ${classes.invoiceOrderText} ${classes.textAlign}`
    }
  } 
// Customizable Area End

export class InvoicePdf extends PdfController {
    constructor(props: any) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { props } = this
        const { classes } = props
        return (

            <StyledDialog
            maxWidth={"lg"}

            open={this.props.openPdf}
            onClose={this.props.onClose}

            >
                <Grid container style={{ justifyContent: "end", padding: "3rem 3rem 0 3rem", }}>
                    <CloseIcon
                        style={{ fontSize: 30 }}
                        onClick={this.props.onClose}
                    />
                </Grid>
                <div style={{ padding: "3rem" }} className="requestPdf">
                {this.state.requestPdfDetails?.pdf_url?.url ? (
               <iframe
      src={this.state.requestPdfDetails.pdf_url.url}
            width="100%"    height="600px"    title="PDF Viewer" style={{ border: "none" }}
             ></iframe>
             ) : (
     <p></p>
                  )}


                </div>
                <Box className={classes.downloadButtonLayout}>
                    <CreateQuote data-test-id="downloadPdf" >
                        <Typography component={'a'}
                            href={this.state.requestPdfDetails?.pdf_url?.url}
                            download
                            target="_blank"
                            className={classes.downloadPdfText}
                            variant="subtitle2"
                        >
                             {t('request.button.download')}
                        </Typography>
                    </CreateQuote>
                </Box>
                <LoadingBackdrop
                    loading={this.state.pdfLoading}
                />
            </StyledDialog>

        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    companyLogo: {
        width: "100px",
        height: "100px"
    },
    lamsaLogo: {
        width: "125px",
        height: "40px"
    },
    titleDe:{
        display:"flex", 
        flexDirection:"column" as const,
        alignItems: 'end' ,
        textAlign: 'end' as const,
        gap:20
    },
    requestUserDetails:{
        borderBottom:"1px solid black",
        paddingBottom:20,
        margin: "20px 0"
    },
    spaces:{
        margin:"0 0 20px 0"
    },
    footerSpace:{
        margin:"50px 0 20px 0"
    },
    fontDark: {
        fontWeight: 500 ,
        color: "black",
    },
    fontDarkspace:{
        fontWeight: 500 ,
        color: "black",
        margin:"0 0 20px 0"
    }
}
const TableStyle = styled(Box)({
    "& .table-header":{
        
        "& .title-th-des":{
            display:"flex",
            justifyContent:"start",
            alignItems:"center",
            height:"45px !important",
        },
        height:"45px !important",
        "& .MuiTableCell-root":{
            border:"none",
            fontWeight: 500 ,
            color: "black",
        },
        "& th":{
            background:"#f0f0f0",
            "&:last-child":{
                borderRadius: "0 6px 6px 0",
            },
            "&:first-child":{
                borderRadius: "6px 0 0 6px",
                minWidth: 30
            },
        }
    },
    "& .table-body":{
       
        "& .MuiTableCell-root":{
            padding:"0px !important"
        },
        "& th":{
            "&:first-child":{
                minWidth: 30
            },
        },
        "& tr":{
            height:"50px !important",
        },
    },
    "& .borderRemove":{
       borderBottom:"none !important"
    },
    "& .colDark":{
        fontWeight: "500 !important" ,
        color: "black !important",
    },
})

export const styles: any = (theme: any) => ({
    customerText: {
        color: CONSTANTS.color.textLightGrey,
        fontSize: "16px",
        lineHeight: "1.2rem",
        fontWeight: CONSTANTS.fontWeight.fontWeight400,
        paddingLeft: "3px"
    },
    tableRow1Height: {
        height: '30px !important'
    },
    tableRowHeight: {
        height: '20px !important'
    },
    paddingRight: {
        paddingRight: "0px !important"
    },
    paddingRight_10: {
        '& th': {
            paddingRight: '10px !important'
        }
    },
    paddingRight_15: {
        '& td': {
            paddingRight: '9px !important'
        }
    },
    textAlign: {
        textAlign: 'start !important'
    },
    textEnd: {
        textAlign: 'end !important'
    },
    alignRight:{
        align:"right"
    },
    borderNone: {
        borderBottom: 'none !important',

    },
    borderBottom: {
        borderBottom: "1px solid gray !important",
        minWidth: '70px !important'
    },
    downloadBtn: {
        width: "200px",
        height: "67px",
        "&:hover": {
            background: CONSTANTS.color.darkBlue,
        },
        [theme.breakpoints?.down("sm")]: {
            marginTop: "10px"
        }
    },
    mt_10: {
        marginTop: "10px"
    },
    mt_15: {
        marginTop: "15px"
    },
    mt_20: {
        marginTop: "20px"
    },
    mt_60: {
        marginTop: "60px"
    },

    fileName: {
        color: CONSTANTS.color.lightBrown,
        fontSize: "16px",
        fontWeight: CONSTANTS.fontWeight.fontWeight500,
        padding: "3px",
        marginTop: '25px'
    },
    invoiceOrderText: {
        color: CONSTANTS.color.lightBrown,
        fontWeight: CONSTANTS.fontWeight.fontWeight500,
        padding: "3px"
    },
    invoiceText: {
        color: CONSTANTS.color.lightBrown,
        fontSize: "16px",
        fontWeight: CONSTANTS.fontWeight.fontWeight500,
        lineHeight: "20px",
        padding: "3px"
    },
    lamsaLogo: {
        width: "125px",
        height: "40px"
    },
    companyLogo: {
        width: "100px",
        height: "100px"
    },
    containerLayout: {
        padding: "8px",
        width: "100%",
        height: "100%"
    },
    innerContainer: {
        padding: "3rem 3rem 30rem",
    },
    downloadButtonLayout: {
        display: "flex",
        justifyContent: "flex-end",
        padding: " 0 3rem 3rem",
        alignItems: "center"
    },
    downloadPdfText: {
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightRegular,
        textAlign: "center",
        textDecoration: 'none'
    },
});


const InvoicePdfStyle: any = withCommonStyles(InvoicePdf)
export default withStyles(styles)(InvoicePdfStyle);
//@ts-ignore
// Customizable Area End