import React from "react";

// Customizable Area Start
import {
  Grid,
  withWidth
} from "@material-ui/core";
import {
  Theme,
  withStyles
} from "@material-ui/core/styles";
import { StyledRoot } from "../../dashboard/src/utility.web";
import { CONSTANTS } from "../../../components/src/theme";
import { DashboardSideBar } from "../../dashboard/src/DashboardSideBar.web";
import { DashboardNotifications} from "./DashboardNotifications.web"
import { withSnackbar } from "../../../components/src/toastweb"
import { withTranslation } from "react-i18next";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
    
      <>
      
        <DashboardSideBar
          permissions={undefined}
          {...this.props}
          navMenuItem={this.props.t('dashboardNavbar.notifications')}
          active={false}
          active1={false}
          active2={false}
          history={undefined}
          oldProps={undefined}
        />
        <StyledRoot languageSelected={this.state.lang}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} style={{width:"100%"}}>
              <DashboardNotifications diaLogBox={false} updateNotificatinCount={null} 
                sliceString={['xs'].includes(this.props.width) ? 19 : 58} 
                history={undefined} infiniteScroll={true} {...this.props}
                 per_page={30}/>
          </Grid>
        </Grid>
        </StyledRoot>
    

      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  boxContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      position: "relative" as const,
    }
  },

  dialogBoxIcon: {
    height: "9px",
    width: "20px",
    position: "fixed" as const,
    top: "75px",
    right: "306px",
    zIndex: 10000,

    [theme.breakpoints.down("md")]: {
      right: "290px",
    },
    [theme.breakpoints.down("sm")]: {
      right: "180px",
    },

  },
  imgStyles: {
    width: "54px",
    height: "54px",
    [theme.breakpoints.down("xs")]: {
      width: "32px",
      height: "32px"
    },
    borderRadius:"50%"
  },
  popoverItems:{
    display:"flex",
    gap:"0.5rem",
    alignItems:"center",
    cursor:"pointer"
  },
  smallPopup:{
    width: "145px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    gap: "6px",
    padding: "0.5rem",
   
  },
  smallPopupAr:{
    width: "172px",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    gap: "6px",
    padding: "0.5rem",
   
  },
  createPopup: {
    width: "100%",
    marginTop:"6rem",
    borderRadius: "5px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    minHeight:"500px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "6px",
    padding: "1rem",
  },

  notifyHeadingBox:{
    display: "flex", width: "100%", alignItems: "center"
  },
  notifyheading: {
    color: "#34313a",
    fontWeight: CONSTANTS.fontWeight.fontWeight400
  },
  markRead: {
    color: "#1c18af",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    cursor: "pointer"
  },

  deleteText: {
    color: "#EB220A",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    cursor: "pointer"
  },


  MoreIcon: {
    color: "#636363",
    alignSelf: "flex-start",
    cursor: "pointer"
  },
  MoreIconxs: {
    color: "#636363",
    cursor: "pointer"
  },
  ReadLess: {
    color: "blue",
    marginLeft: "5px", cursor: "pointer"
  },
  CheckIcon: {
    color: "#0b9500", fontSize: "1.2rem"
  },
  DeleteIcon: {
    color:"#e02020",fontSize:"1.2rem"
  },
  markReadParent: {
    display: "flex",
    flexDirection: "row" as const,
    gap: "1rem",
    marginLeft: "auto",
  },
  markReadParentAr: {
    display: "flex",
    flexDirection: "row" as const,
    gap: "1rem",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "75%",
    },
  },

  MarkReadColor: {
    color: "#0b9500",
    cursor:"pointer"
  },

  MarkUnreadColor: {
    color: "#636363",
    cursor:"pointer"
  },
  unreadColor:{
    color: "#636363",
    fontSize: "1.2rem"
  },
  DeleteColor: {
    color: "#e02020",
    cursor:"pointer"
  },
  Hr:{
    margin: "1rem 0 1rem 0"
  },
  todayDate: {
    color: "#34313a",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    cursor: "pointer"
  },
  notifyBody: {
    color: "#636363",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    fontFamily: "rubik",
   
    wordBreak: "break-word" as const,
    [theme.breakpoints.down("xs")]: {
      // textAlign: "inherit" as const,
    },
  },
  notifyTitleWidth: {
    width: "76%",
    [theme.breakpoints.down("xs")]: {
      width: "55%",
    },
  },
  noNotifyText: {
    color: "#535353",
    fontWeight: 400,
    textAlign: "center" as const,
    marginTop:"auto"
  },
  notifyTitle: {

    color: "#34313a",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
  },
  hideLarge :{
    [theme.breakpoints.up("sm")]: {
      display: "none"
    },
  },
  hideSmall :{
    [theme.breakpoints.down("xs")]: {
      display:"none !important"
     },
  },
  notifyContainer: {
    display: "flex",
    flexDirection: "row" as const,
    width: "100%",
    gap: "1rem",
    alignItems: "center" as const,
    marginBottom:"1rem",
    [theme.breakpoints.down("xs")]: {
      gap: "0.5rem",
    },
    cursor:"pointer"
  },
  badgeIcon: {
    color: "#1C18AF",
    fontSize: "0.8rem",
    position: "absolute" as const,
    left: "-6px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.5rem",
    },
  },
  badgeIconAr: {
    color: "#1C18AF",
    fontSize: "0.8rem",
    position: "absolute" as const,
    right: "-6px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.5rem",
    },
  },
  notifyCount:{
    width: "25px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#1c18af",
    color: "white"
  },

  notifyImageParent: {
    display: "flex",
    position:"relative" as const
  },
  notifyBodyContainer: {
    display: "flex", flexDirection: "column" as const, 
    width: "100%"
  },
  notifyBodyParent: {
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "space-between",
    alignItems: "flex-start" as const
  },
  
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },

};
const NotificationStyles  = withTranslation()(withSnackbar(withStyles(styles)(withWidth()(Notifications))));
export { NotificationStyles}
// Customizable Area End
