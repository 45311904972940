import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputBase,
  CircularProgress,Button,IconButton,InputAdornment
} from "@material-ui/core";
import { styled, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import {searchIcon} from "./assets";
import AutocompleteV1 from "@material-ui/lab/Autocomplete";
import { countryCodes } from "../../dashboard/src/utility.web";
import "./CreateCustomer.css";
import { StyledTextFieldsAutoComplete } from "./CreateSupplier.web";
import { GoogleMap, Marker, Autocomplete } from "@react-google-maps/api";
import { CONSTANTS } from "../../../components/src/theme";
import ConfirmationModal from "../../../components/src/ConfirmationModal";
import { withTranslation } from "react-i18next";
import InternalNotesAttachements from "../../RequestManagement/src/InternalNotesAttachements.web";
import {SitesStyledDialog,SitesStyledButtons,SitesStyledBox,SitesStyledGridLocation} from "./CustomerSites.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// Customizable Area End

import CreateCustomersController, {
  Props,
} from "./CreateCustomerController.web";


// Customizable Area Start
export const StyledBoxContainer = styled(Box)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "6rem !important",
  },
}));

export const StyledBox = styled(Box)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "2.5rem 1rem 0rem !important",
  },
}));

export const RadioBox = styled(Box)(({ theme }: any) => ({
  [theme.breakpoints.down("xs")]: {
  maxWidth:"73px",
  maxHeight:"35px"
  },
}));



export const StyledBoxInternal = styled(Box)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "0.5rem 1rem 0rem !important",
  },
}));

export const StyledTypography = styled(Typography)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "1rem",
  },
  fontSize: "1.4286rem",
  [theme.breakpoints.down("xs")]: {
    fontSize: 24,
  },
  fontFamily: "Rubik"
}));


export const StyledGrid = styled(Grid)(({ theme }: any) => ({
  [theme.breakpoints.down("sm")]: {
    "& > .MuiGrid-item": {
      padding: "8px",
    },
  },
}));

export const StyledTextFields = withStyles({
  root: {
    width:"100%",
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      fontFamily: "Rubik",
      color: CONSTANTS.color.lightBrown,
    },
  },
})(TextField);





export const StyledTextFieldAutoComplete = withStyles((theme: Theme) => (
  {
    root: {
      "& .MuiInputBase-root": {
        height: "55px",
        borderRadius: "10px",
        fontSize: "1.1667rem",
        [theme.breakpoints.down("xs")]: {
          fontSize: "1.0625rem !important"
        },
        fontFamily: "Rubik",
        color: CONSTANTS.color.lightBrown,
      },
      "& .MuiOutlinedInput-input": {
        cursor: "pointer",
        padding: "15px 14px",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#05037a",
        borderWidth: "2px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "2rem",
        marginTop: "-4px",
      },
    },
  }
))(TextField);

// Customizable Area End

export class CreateCustomer extends CreateCustomersController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start


  renderActionButton = (webStyle: any) => {
    const { classes,t }: any = this.props;

    return (
      <Box
        data-test-id="save-site"
        className={`${classes.siteCancelBtn} ${classes.CreateCustbuttonContainerSave}`}
        onClick={this.handleSaveSiteInformation}
      >
        <Typography variant="subtitle2" style={webStyle.detailsbuttonTextSave}>{t("Calendar.button.save")}</Typography>
      </Box>
    )

  };


  reverseOrder = (item: any, mode: string) => {
    const { t,classes } = this.props;
    if (mode === "reverse") {
      return (
        <React.Fragment>
          <Typography
            variant="body2"
            style={
              item.enable === false
                ? { ...webStyle.CreateCustonText, color: "rgba(0,0,0,0.5)" }
                : webStyle.CreateCustonText
            }
          >
            {item.enable === false
              ? t(`customers.createCustData.${0}.radioDisableLabel`)
              : t(`customers.createCustData.${0}.radioEnableLabel`)}
          </Typography>
          <img
            className={classes.CreateCustradioButton}
            src={item.enable === false ? item.radioDisable : item.radioEnable}
            alt=""
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <img
            className={classes.CreateCustradioButton}
            src={item.enable === false ? item.radioDisable : item.radioEnable}
            alt=""
          />
          <Typography
            variant="body2"
            style={
              item.enable === false
                ? { ...webStyle.CreateCustonText, color: "rgba(0,0,0,0.5)" }
                : webStyle.CreateCustonText
            }
          >
            {item.enable === false
              ? t(`customers.createCustData.${0}.radioDisableLabel`)
              : t(`customers.createCustData.${0}.radioEnableLabel`)}
          </Typography>
        </React.Fragment>
      );
    }
  };
  
  showMockRemindersWeb = (specialIds: number[]) => {
    const {classes,t} =this.props;
    return this.state.mockAutomaticRemindersData.map((item: any,index:number) => (
      <Box style={{ paddingTop: "0.5rem" }} key={item.id}>
        <Typography variant="subtitle2" className={classes.remainderTextHeading} style={webStyle.CreateCustquoteText}>
        {t(`customers.createCustData.${index}.title`)}
        </Typography>
        <Box className={classes.CreateCustquoteContainer}>
          <Typography variant="body1" className={classes.remainderText} style={webStyle.CreateCustfollowUpText}>
          {t(`customers.createCustData.${index}.desc`)}
            <span
              onClick={this.handleGoToSettings}
              style={{ color: "#1c18af", cursor: "pointer", marginLeft:"3px" }}
            >
             {t(`customers.createCustData.${0}.button`)}
            </span>
          </Typography>
          <RadioBox
            data-test-id="handleRadioChange"
            className={
              item.enable === false
                ? `${classes.CreateCustradioButtonContainer} ${classes.bg}`: `${classes.CreateCustradioButtonContainer} ${classes.lightBlue_bg}`
            }
            onClick={() => this.handleRadioChange(item, "CREATE")}
          >
            {item.enable ?
              this.reverseOrder(item, "reverse")
              :
              this.reverseOrder(item, "")
            }
          </RadioBox>
        </Box>
        {specialIds.includes(item.id) && (
          <span className={classes.CreateCusthr}></span>
        )}
      </Box>
    ));
  };

  renderAutoCompleteInput = (params: any) => (
    <StyledTextFieldsAutoComplete languageSelected={this.state.lang} {...params} fullWidth variant="outlined" />
  );

  //  Customizable Area End

  render() {
    // Customizable Area Start

    const specialIds = [1, 2 ,3];
    const { name, email, phone,notes } =
      this.state.customerInformationFormData;
      const { site_name, site_Email, site_Phone,  state, city, zip, site_address, country } =
      this.state.siteInformationFormData;
    const { classes, width ,t } = this.props;
    const isMobile = ['xs'].includes(width);
    return (
      <>
        <SitesStyledDialog
          open={this.state.siteDialogOpen}
          onClose={this.handleCloseSiteDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <Grid container>
            <Grid item lg={8} md={12} xs={12}>
              <SitesStyledBox style={webStyle.detailsformContainer}>
                <StyledTypography style={webStyle.detailspersonalInfoText}>
                {t("customers.siteDialog.siteInformation")}
                </StyledTypography>
                <Grid item lg={12} md={12} xs={12}>
                  <StyledGrid container spacing={2}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.detailsgridItem}
                    >
                      <Typography
                        style={webStyle.detailscontactForm}
                        variant="body2"
                        className={classes.customerInputSubTitles}
                        gutterBottom
                      >
                         {t("customers.siteDialog.name")} <span style={webStyle.redColordetails}>*</span>
                      </Typography>
                      <StyledTextFields
                        disabled={!this.state.showFields}
                        data-test-id="form-data"
                        fullWidth
                        variant="outlined"
                        name="site_name"
                        value={site_name}
                        onChange={this.handleChangeFormDataSites}
                        error={Boolean(this.state.isErrorFormData.name)}
                      />
                      <Typography
                        variant="body2"
                        style={webStyle.detailserrorMessage}
                      >
                        {this.state.isErrorFormData.name}
                      </Typography>
                    </Grid>
                  </StyledGrid>
                  <StyledGrid container spacing={3}>
                  <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={{
                        ...webStyle.detailsgridItem,
                        overflow: "auto",
                        position: "relative",
                      }}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}
                        style={webStyle.detailscontactForm}
                        variant="body2"
                        gutterBottom
                      >
                        {t("BoAuth.emailLabel")}
                      </Typography>           
                        <StyledTextFieldAutoComplete
                          disabled={!this.state.showFields}
                          fullWidth
                          variant="outlined"
                          name="site_Email"
                          value={site_Email}
                          onChange={this.handleChangeFormDataSites}
                        />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={{
                        ...webStyle.detailsgridItem,
                        overflow: "auto",
                        position: "relative",
                      }}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}

                        style={webStyle.detailscontactForm}
                        variant="body2"
                        gutterBottom
                      >
                        {t("profileSetup.phoneNumber")}
                      </Typography>           
                        <StyledTextFieldAutoComplete
                          disabled={!this.state.showFields}
                          fullWidth
                          variant="outlined"
                          name="site_Phone"
                          value={site_Phone}
                          onChange={this.handleChangeFormDataSites}
                        />
                    </Grid>
                  </StyledGrid>
                </Grid>
              </SitesStyledBox>
              <SitesStyledBox style={webStyle.detailsaddressFormContainer}>
                <Grid item lg={12} md={12} xs={12}>
                  <StyledGrid container spacing={3}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={{
                        ...webStyle.detailsgridItem,
                        overflow: "auto",
                        position: "relative",
                      }}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}

                        style={webStyle.detailscontactForm}
                        variant="body2"
                        gutterBottom
                      >
                        {t("customers.siteDialog.address")} <span style={webStyle.redColordetails}>*</span>
                      </Typography>

                    
                        <StyledTextFieldAutoComplete
                          disabled={!this.state.showFields}
                          fullWidth
                          variant="outlined"
                          name="site_address"
                          value={site_address}
                          onChange={this.handleChangeFormDataSites}
                          error={Boolean(
                            this.state.isErrorFormData.site_address
                          )}
                         
                        />
                     
                      <Typography
                        variant="body2"
                        style={webStyle.detailserrorMessage}
                      >
                        {this.state.isErrorFormData.site_address}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.detailsgridItem}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}

                        style={webStyle.detailscontactForm}
                        variant="body2"
                        gutterBottom
                      >
                          {t("customers.siteDialog.state")}
                      </Typography>
                      <StyledTextFields
                        disabled={!this.state.showFields}
                        data-test-id="form-data"
                        fullWidth
                        variant="outlined"
                        name="state"
                        value={state}
                        error={Boolean(this.state.isErrorFormData.state)}
                        onChange={this.handleChangeFormDataSites}
                      />
                      <Typography
                        variant="body2"
                        style={webStyle.detailserrorMessage}
                      >
                        {this.state.isErrorFormData.state}
                      </Typography>
                    </Grid>
                  </StyledGrid>
                  <StyledGrid container spacing={3}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.detailsgridItems}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}

                        style={webStyle.detailscontactForm}
                        variant="body2"
                        gutterBottom
                      >
                        {t("customers.siteDialog.city")}
                      </Typography>
                      <StyledTextFields
                        disabled={!this.state.showFields}
                        data-test-id="form-data"
                        fullWidth
                        variant="outlined"
                        name="city"
                        error={Boolean(this.state.isErrorFormData.city)}
                        value={city}
                        onChange={this.handleChangeFormDataSites}
                      />
                      <Typography
                        variant="body2"
                        style={webStyle.detailserrorMessage}
                      >
                        {this.state.isErrorFormData.city}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.detailsgridItems}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}

                        style={webStyle.detailscontactForm}
                        variant="body2"
                        gutterBottom
                      >
                        {t("profileSetup.country")}
                      </Typography>
                      <StyledTextFields
                        disabled={!this.state.showFields}
                        data-test-id="form-data"
                        fullWidth
                        variant="outlined"
                        name="country"
                        error={Boolean(this.state.isErrorFormData.country)}
                        value={country}
                        onChange={this.handleChangeFormDataSites}
                      />
                      <Typography
                        variant="body2"
                        style={webStyle.detailserrorMessage}
                      >
                        {this.state.isErrorFormData.country}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.detailsgridItems}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}

                        style={webStyle.detailscontactForm}
                        variant="body2"
                        gutterBottom
                      >
                     {t("customers.siteDialog.zipCode")}
                      </Typography>
                      <StyledTextFields
              
                        disabled={!this.state.showFields}
                        data-test-id="form-data-sites"
                        fullWidth
                        variant="outlined"
                        name="zip"
                        error={Boolean(this.state.isErrorFormData.zip)}
                        value={zip}
                        onChange={this.handleChangeFormDataSites}
                      />
                      <Typography
                        variant="body2"
                        style={webStyle.detailserrorMessage}
                      >
                        {this.state.isErrorFormData.zip}
                      </Typography>
                    </Grid>
                  </StyledGrid>
                </Grid>
              </SitesStyledBox>
            </Grid>
            <SitesStyledGridLocation
              item
              lg={4}
              md={12}
              xs={12}
              style={{...webStyle.detailsrightContent,direction:localStorage.getItem('lang')==='ar'?'ltr':'rtl'}}
            >
              <StyledTypography
                style={{
                  ...webStyle.detailspersonalInfoText,
                  lineHeight: "normal",
                  margin: "-2rem 0 1rem 0",
                }}
              >
              {t("customers.siteDialog.location")}
              </StyledTypography>

              <GoogleMap
                mapContainerStyle={webStyle.detailsmapContainerStyle}
                center={this.state.currentPosition || this.state.center}
                zoom={5}
                onLoad={(map: any) => {
                  this.mapRef.current = map;
                }}
                options={{
                  disableDefaultUI: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
              >
                <div className={classes.autoCompletedetails} style={webStyle.autoCompletedetails}>
                  <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                    <Box sx={{ marginTop: "1.063rem"}}>
                    <img
                      style={webStyle.CustsearchIcon}
                      src={searchIcon}
                      alt=""
                    />   
                    </Box>
                    <Autocomplete
                      onPlaceChanged={this.onPlaceChanged}
                      onLoad={this.onLoad}
                    >
                       <Box>
                    <InputBase
                    data-test-id="address-input"
                        fullWidth
                        name="autoCompleteInput"
                        style={webStyle.autoCompleteInputdetails}
                        value={this.state.autoCompleteInput}
                        placeholder={"Search.."}
                        inputProps={{ "aria-label": "search" }}
                        onChange={this.handleChangeFormDataInputSites}
                        onKeyPress={this.handleKeyPress}
                        endAdornment={
                          
                            <InputAdornment position="end">
                              <IconButton  className="buttonArrow" style={{ backgroundColor:"#1c18af", width: "56px", height: "56px",color: 'white',
                              marginRight:"1px",cursor:"pointer",borderRadius:"8px"}} edge="end">
                                {localStorage.getItem("lang")==='ar'?<ArrowBackIcon />:<ArrowForwardIcon />}
                              </IconButton>
                            </InputAdornment>
                          
                        }
                      />  
                      </Box>  
                      </Autocomplete>            
                  </Box>
                  
                </div>
                {this.state.selectedPlace && (
                  <Marker
                    position={this.state.selectedPlace}
                    draggable={true}
                    onDragEnd={this.handleMarkerDrag}
                  />
                )}

                {!this.state.selectedPlace && this.state.currentPosition && (
                  <Marker 
                    position={this.state.currentPosition}
                    draggable={true}
                    onDragEnd={this.handleMarkerDrag}
                  />
                )}
              </GoogleMap>
            </SitesStyledGridLocation>
          </Grid>

          <SitesStyledButtons
            style={{
              ...webStyle.detailsbuttonsContainer,
              padding: "2rem 0px 0rem 1.5rem",
              marginBottom: "1rem",
            }}
          >
            <Box style={{ display: "flex", width: "100%" }} className={classes.siteCancelBtnLayout} >
              <Box
                className={`${classes.siteCancelBtn} ${classes.CreateCustbuttonContainerCancel}`}
                onClick={this.handleCloseSiteDialog}
              >
                <Typography variant="subtitle2" style={webStyle.detailsbuttonTextCancel}>
                 {t("Calendar.button.cancel")}
                </Typography>
              </Box>
            </Box>
            {this.renderActionButton(webStyle)}
          </SitesStyledButtons>
        </SitesStyledDialog>
        <ConfirmationModal
          open={this.state.confirmationModal}
          title={t("customers.confirmationModal.title")}
          content={t("customers.confirmationModal.content")}
          cancel={t("Calendar.button.cancel")}
          leave={t("customers.confirmationModal.leave")}
          handleCancel={this.handleGoToSettings}
          handleLeave={this.handleGoToSettingsCallback}
          languageSelected={this.state.lang}
          isMobile={isMobile}
         />
       
        <StyledBoxContainer
          style={webStyle.CreateCustpersonalInformationContainer}
        >
          <Grid container spacing={2}>
            <Grid item lg={8} md={8} xs={12}>
              <StyledBox style={webStyle.createCusFormContainer}>
                <StyledTypography
                  style={webStyle.CreateCustpersonalInfoText}>
                 {t("customers.formData.personalInformation")}
                </StyledTypography>
                <Grid item lg={12} md={12} xs={12}>
                  <StyledGrid container spacing={2}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.CreateCustgridItem}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}
                        style={webStyle.CreateCustcontactForm}
                        variant="body1"
                        gutterBottom
                      >
                        {t("customers.siteDialog.name")} <span style={webStyle.redColor}>*</span>
                      </Typography>
                      <StyledTextFields
                        data-test-id="formdata"
                        fullWidth
                        data-testid='customerName'
                        variant="outlined"
                        name="name"
                        value={name}
                        onChange={this.handleChangeFormData}
                        error={Boolean(this.state.isErrorFormData.name)}
                      />
                      <Typography
                        variant="body2"
                        style={webStyle.CreateCusterrorMessage}
                      >
                        {this.state.isErrorFormData.name}
                      </Typography>
                    </Grid>
                  </StyledGrid>
                  <StyledGrid container spacing={3}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.CreateCustgridItems}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}
                        style={webStyle.CreateCustcontactForm}
                        variant="body1"
                        gutterBottom
                      >
                        {t("BoAuth.emailLabel")}
                      </Typography>
                      <StyledTextFields
                        data-test-id="formdata"
                        fullWidth
                        variant="outlined"
                        name="email"
                        value={email}
                        onChange={this.handleChangeFormData}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      xs={12}
                      style={webStyle.CreateCustgridItems}
                    >
                      <Typography
                        className={classes.customerInputSubTitles}
                        style={webStyle.CreateCustcontactForm}
                        variant="body1"
                        gutterBottom
                      >
                       {t("profileSetup.phoneNumber")}
                      </Typography>
                      <Box style={webStyle.CreateCustphoneContainer}>
                        <AutocompleteV1
                          id="country-select-demo"
                          style={{ width: "34%" , color:CONSTANTS.color.lightBrown}}
                          options={countryCodes}
                          value={this.state.countryCode}
                          onChange={this.handleChangeCountryCode}
                          autoHighlight
                          renderInput={this.renderAutoCompleteInput.bind(this)}
                        />
                        <InputBase
                          data-test-id="formdata"
                          style={webStyle.CreateCustinputBase}
                         
                          className={
                            this.state.lang === "ar" ?
                            `${classes?.CreateCustinputBase} ${classes?.CreateCustinputBaseAr}` :
                            classes?.CreateCustinputBase
                          }
                          name="phone"
                          value={phone}
                          onChange={this.handleChangeFormData}
                          error={Boolean(this.state.isErrorFormData.phone)}
                        />
                      </Box>
                      <Typography
                        variant="body2"
                        style={webStyle.CreateCusterrorMessage}
                      >
                        {this.state.isErrorFormData.phone}
                      </Typography>
                    </Grid>
                  </StyledGrid>
                </Grid>
              </StyledBox>
            </Grid>
          
          </Grid>
          
          <StyledBoxInternal style={webStyle.CreateCustinternalFormContainer}>
          <StyledGrid container spacing={3}>
          <Grid item xs={12} md={8} lg={8}>
            <InternalNotesAttachements
              t={t}
              editStatus={true}
              type={localStorage.getItem("editedCustomerId") && "edit"}
              internalNotes={notes}
              internalAttachments={this.state.files}
              editedinternalAttachements={this.state.editedFiles}
              convertedFiles={this.state.convertedFiles}
              getConvertedFiles={this.getJobConvertedFiles}
              getEditedFiles={this.getJobEditedFiles}
              updateInternalNotes={this.updateInternalNotes}
              languageSelected={this.state.lang}
              updateInternalAttachments={this.updateInternalAttachments} />
            <Typography variant="caption" color="error">{this.state.isErrorFormData.notes}</Typography>
            <StyledTypography
              style={{
                ...webStyle.CreateCustpersonalInfoText,
                lineHeight: "normal",
                padding: "1.5rem 0 1rem 0",
              }}
            >
              {t("customers.formData.automaticReminders")}
            </StyledTypography>
            <Box>{this.showMockRemindersWeb(specialIds)}</Box>
              </Grid>
          </StyledGrid>
          </StyledBoxInternal>
        </StyledBoxContainer>

        <Box style={webStyle.CreateCustbuttonsContainer}>
          <Box style={{ display: "flex", width: "100%", gap: "20px"}}>
            <Button
              className={`${classes.siteCancelBtn} ${classes.CreateCustbuttonContainerCancel}`}
              onClick={this.handleCancelButton}
              data-test-id="cancel_direction_button"
            >
              <Typography variant="subtitle2" style={webStyle.CreateCustbuttonTextCancel}>
              {t("Calendar.button.cancel")}
              </Typography>
            </Button>
          </Box>

          <Button
            className={`${classes.siteCancelBtn} ${classes.CreateCustbuttonContainerSave}`}
            onClick={this.handleSaveCustomerInformation}
          >
            {this.state.createCustomersLoading ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              <Typography variant="subtitle2" style={webStyle.CreateCustbuttonTextSave}>
               {t("languageSupport.nextButton")}
              </Typography>
            )}
          </Button>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) =>

  createStyles({
  
  });

const webStyle = {
  CreateCustmapContainerStyle: {
    maxWidth: "100%",
    minHeight: "330px",
    borderRadius: "10px",
  },
  CreateCustmainContainer: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    alignItems: "center",
    gap: "15px",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
  CreateCustinformationContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "174px",
    minHeight: "46px",
    borderRadius: "8px",
    border: "1px solid rgba(0,0,0,0.1)",
  },
  CreateCustinformationText: {
    color: "#626262",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 400,
  },
  createCusFormContainer: {
    padding: "2.5rem 1rem 2rem 2rem",
  },
  CreateCustpersonalInformationContainer: {
    marginTop: "0.5rem",
    paddingBottom: "3rem",
    maxWidth: "100%",
    minHeight: "1137px",
    backgroundColor: "#ffffff",
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
  },
  CreateCustaddressFormContainer: {
    padding: "0.5rem 2rem 2rem 2rem",
  },
  CreateCustinternalFormContainer: {
    marginTop:"1rem",
    padding: "0rem 2rem 2rem 2rem",
  },
  CreateCustpersonalInfoText: {
    color: "#34313a",
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
  },
  CreateCustgridItem: {
    marginTop: "1.5rem",
  },
  CreateCustgridItems: {
    marginTop: "1rem",
  },
  CreateCustcontactForm: {

  },
  CreateCusterrorMessage: {
    marginTop: "5px",
    color: "red",

    fontWeight: 500,
  },
  CreateCustlocationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  CreateCustlocationIcon: {
    width: "24px",
    height: "24px",
  },
  CreateCustlocationNames: {
    color: "#2b4038",
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 400,
    marginLeft: "0.5rem",
  },
  CreateCustcountryCodeContainer: {
    display: "flex",
    borderRadius: "10px 0 0 10px",
    maxWidth: "88px",
    width: "100%",
  },
  CreateCustinputBase: {
    fontFamily: "Rubik",
    fontSize: "1.1167rem",
    fontWeight: 400,
    padding: "0 10px",
    width: "100%",
    border: "0.5px solid lightgrey",
    borderRadius: "0 8px 8px 0",
    color:CONSTANTS.color.lightBrown
  },
  
  CreateCustphoneContainer: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #dedede",
    display: "flex",
    minHeight: "56px",
  },
  CreateCustrightContent: {
    paddingTop: "4.5rem",
  },

  CreateCustquoteText: {
    color: "rgba(0,0,0,0.5)",
    fontWeight: 500,
  },
  CreateCustfollowUpText: {
    color: "#999999",
    fontWeight: 400,
  },
  CreateCustonText: {
    color: "#1c18af",

    fontWeight: 500,
  },
  CreateCustbuttonsContainer: {
    padding: "4rem 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  CreateCusttoaster: {
    position: "fixed" as const,
    display: "flex",
    alignItems: "center",
    paddingLeft: "2rem",
    width: "100%",
    maxWidth: "689px",
    minHeight: "80px",
    borderRadius: "8px",
    backgroundColor: "#1c18af",
    marginTop: "5px",
  },
  CreateCusttoasterText: {
    color: "#ffffff",
    fontWeight: 400,
  },
  CreateCustcloseIcon: {
    position: "absolute" as "absolute",
    left: "96%",
    top: "29%",
    transform: "translate(-50%, -50%)",
    color: " #fff",
    cursor: "pointer",
  },
  CreateCustbuttonTextCancel: {
    fontWeight: 500,
    textAlign: "center" as "center",
  },
  CreateCustbuttonTextSave: {
    color: "#ffffff",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  CreateCustUploadFilesName: {
    width: "200px",
    height: "90px",
    marginRight: "5px",
    marginBottom: "5px",
    borderRadius: "8px",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 6px 17px rgba(28,24,175,0.2)",
  },
  CreateCustUploadFileBox: {
    width: "100%",
    margin: 0,
    display:"flex"
  },
  CreateCustUploadTypo: {
    color: "#34313a",
    fontWeight: 400,
    textAlign: "right" as const,
    marginBottom: 0,
    padding: "3px 5px 0 0",
    cursor: "pointer",
    marginLeft:"auto"
  },
  CreateCustImageBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 2px 0 9px",
  },
  CreateCustImageBoxV1: {
    marginRight: "9px",
  },
  CreateCustImage: {
    width: "38px",
    height: "40px",
  },
  CreateCustTextBox: {
    display: "flex",
    flexDirection: "column" as const,
  },
  CreateCustTextTypo: {
    color: "#34313a",
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: 400,
    marginBottom: 0,
  },
  CreateCustUploadGrid: {
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    alignItems: "center",
    padding: 0,
  },
  redColor: {
    color: "red"
  },
    detailsformContainer: {
    padding: "2.5rem 1.5rem 2rem",
  },
  detailsaddressFormContainer: {
    padding: "0.5rem 1.5rem 2rem",
  },
  detailspersonalInfoText: {
    color: "#34313a",
    fontWeight: 500,
    lineHeight: "20px",
  },
  detailsgridItem: {
    marginTop: "1.5rem",
  },
  detailsgridItems: {
    marginTop: "1rem",
  },
  detailscontactForm: {
    color: "#999999",
    fontWeight: 400,
    marginBottom: "0.8rem",
    lineHeight: "16px",
  },
  detailserrorMessage: {
    marginTop: "5px",
    color: "red",

    fontWeight: 500,
  },
  detailsrightContent: {
    paddingTop: "4rem",
  },
  detailsbuttonTextCancel: {
    fontWeight: 500,
    textAlign: "center" as "center",
  },
  detailsbuttonTextSave: {
    color: "#ffffff",
    fontWeight: 400,
    textAlign: "center" as "center",
  },
  detailsbuttonsContainer: {
    padding: "4rem 0px 4rem 1.5rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  detailsmapContainerStyle: {
    width: '100%',
    maxWidth: "370px",
    height: "370px",
    borderRadius: "10px",
    position:"relative" as const
  },
  redColordetails: {
    color: "red"
  },
  autoCompletedetails:{
    width:"95%",
    display: "flex",
    minHeight: "56px",
    backgroundColor:"white",
    borderRadius: "8px",  
  },
  autoCompleteInputdetails:{
    borderRadius: "10px",
    backgroundColor:CONSTANTS.color.white,
    width: "100%",
    fontFamily: "Rubik",
    fontSize: "1.1667",
    fontWeight: 400,
    lineHeight: "16px",
    color:CONSTANTS.color.grey,
    padding:"12px 0 0 0"
  },
  CustsearchIcon: {
    height: "20px",
    width: "20px",
    margin: "0 0.6rem 0 1.3rem",
  },
  googleIcon : {
    backgroundColor: "transparant", 
    border: "none", 
    width: "100px", 
    height: '30px', 
    position: "absolute", 
    top: '329px', 
    right: '250px' 
  } as const
  
};

export default withTranslation()(withStyles(styles)(CreateCustomer));
// Customizable Area End