import { withStyles, createStyles, Theme, StyleRulesCallback } from "@material-ui/core";
import { CONSTANTS } from '../../../components/src/theme'
const selectedLanguage = localStorage.getItem("lang");
const calendarStyles: StyleRulesCallback<Theme, {}, string> = (theme: Theme) =>
    createStyles({
        customBigCalender: {
            '& .rbc-day-bg': {
                borderLeft: selectedLanguage === 'ar' ? '1px solid #ddd':""
            },
            '& .rbc-month-view .rbc-month-header .rbc-header': {
                borderLeft: selectedLanguage === 'ar' ? '1px solid #ddd':""
            },
            '& .rbc-month-view': {
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
                marginTop: '20px',
                border: 'none',
                '& .rbc-month-header': {
                    '& .rbc-header': {
                        [theme.breakpoints.down("xs")]: {
                            padding: "10px !important"
                        },
                        padding: '25px',
                        fontWeight: 400,
                        color: 'rgb(153, 153, 153)'
                    },
                },
                '& .rbc-month-row': {
                    '& .rbc-row': {
                        '& .rbc-date-cell': {
                            padding: '10px 10px 0px 0px',
                            fontWeight: 600,
                        },
                        '& .rbc-off-range': {
                            color: 'white !important'
                        },
                        '& .rbc-row-segment': {
                            '& .rbc-event': {
                                background: 'none',
                            },
                            '& .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus': {
                                outline: 'none !important'
                            },
                         '& .rbc-day-slot': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column', // Stack events vertically
    width: '100%', // Ensure it uses the full width
    overflow: 'hidden', // Prevent children from overflowing the block
    '& .rbc-event': {
        position: 'relative',
        marginTop: '5px', // Add spacing between stacked events
        padding: '5px', // Padding around the event content
        height: 'auto', // Dynamically adjust height based on content
        width: '100%', // Ensure it spans the full width of the time slot
        left: '0%', // Align events to the start of the block
        zIndex: 1, // Ensure events are above other elements
        boxSizing: 'border-box', // Include padding and borders in size
        whiteSpace: 'normal', // Allow text wrapping for longer content
        wordWrap: 'break-word', // Break text at word boundaries
        overflow: 'hidden', // Prevent overflow beyond the block
        textOverflow: 'ellipsis', // Add ellipsis for truncated text
        backgroundColor: '#f3f3f3', // Optional: Add background for visibility
        borderRadius: '4px', // Round edges for better UI
        fontSize: '0.9rem', // Adjust font size
        lineHeight: '1.2', // Ensure proper spacing for multi-line text
    },
},

                        }
                    },
                    '& .rbc-row-bg': {
                        '& .rbc-off-range-bg': {
                            background: 'none',
                        },
                    }
                },
            },
            '& .rbc-time-view': {
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
                marginTop: '20px',
                border: 'none',
                zIndex:0,
                '& .rbc-header': {
                    borderBottom: 'none',
                    padding: '16px',
                },
            },
            '& .rbc-time-header.rbc-overflowing': {
                borderRight: 'none',
                marginRight:"0px !important"
            },
            '& .rbc-timeslot-group': {
                minHeight: '90px', 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
            '& .rbc-time-slot': {
                color: 'rgb(153, 153, 153)',
                FontWeight: CONSTANTS.fontWeight.fontWeight500
            },
            '& .rbc-time-view .rbc-row': {
                minHeight: '12px',
                color: 'rgb(153, 153, 153)'
            },
            '& .rbc-time-slot .rbc-events-container': {
                bottom: "19%",
                left: 0,
                position: "absolute",
                right: 0,
                top: 0

            },
         '& .rbc-day-slot': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%', // Ensure it uses the correct size
    overflow: 'hidden', // Prevent children from overflowing the block
// Align events to the start of the slot
    '& .rbc-event': {
        position: 'relative',
        height: '12% !important', // Dynamically adjust height based on content
        width: '107% !important', // Dynamically adjust width based on content
        zIndex: 1, // Ensure events are above other elements
        boxSizing: 'border-box', // Include padding and borders in size
        whiteSpace: 'normal', // Allow text wrapping for longer content
        wordWrap: 'break-word', // Break text at word boundaries
        overflow: 'visible', // Show full text without cutting off
        borderRadius: '4px', // Round edges for better UI
        fontSize: '0.9rem', // Adjust font size
        lineHeight: '1.2', // Ensure proper spacing for multi-line text
        left:" 0% !important",
        top: "38.5% !important",
    },
},


            '& .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-event': {
                border: 'none',
                background: 'none !important', // Remove blue background
                boxShadow: 'none !important',  // Remove any box shadow
            },
        },
        customMonthPicker: {
            color: 'rgb(98, 98, 98)',
            fontWeight: 500,
            fontSize: '18px',
            border: 'none',
            boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
            marginLeft: "-15em !important",
            [theme.breakpoints.down('xs')]: {
                marginLeft: "-11em !important",
            },
            "& .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text": {
                width: "5rem !important",
                padding: "18px",
            },
            "& .react-datepicker__header:not(.react-datepicker__header--has-time-select)": {
                background: 'white !important',
                fontSize: "19px",
                color: 'rgb(98, 98, 98)',
                border: "1px solid rgb(229,229,229)",
            },
            "& .react-datepicker__navigation": {
                top: "20px"
            },
            "& .react-datepicker-popper": {
                // zIndex: 9999
            },
            "& .react-datepicker__month-text--selected": {
                background: "none !important",
                color: "#1c18af"
            },
            "& .react-datepicker__month-text--keyboard-selected": {
                background: "none !important",
                color: "#1c18af"
            },
            "& .react-datepicker__month-text--today": {
                fontWeight: 500
            }
        },
    })

export default withStyles(calendarStyles);