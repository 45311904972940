import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import React from "react";
import { getStorageData, setStorageData,removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
import { performInvoiceAuthorizationCheck } from "../../CustomisableUserProfiles/src/utility.web";
import {WithStyles} from "@material-ui/core"
import { apiCall, checkTokenExpired, dateMinusOne, navigateTo } from '../../../components/src/CommonFunction'
export interface Details {
  id?: string,
  type?: string,
  attributes?: CustomerAttributes
}
export interface CustomerAttributes {
  name?: string,
  email?: string,
  phone_number?: string,
  address?: string,
  state?: string,
  city?: string,
  zip_code?: string,
  notes?: string,
  quote_follow_up?: boolean,
  job_follow_up?: boolean,
  invoice_follow_up?: boolean,
  latitude?: string,
  longitude?: string,
  files?: null,
}
interface NewTeamMember {
  value:string
  label:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  history: any
  // Customizable Area Start
  openToastHandler: any;
  open:boolean
  t: (key: string) => string;
  // Customizable Area End
}

interface RequestError {
  id: number;
  formName: string;
  errorName: string;
}

interface S {
  // Customizable Area Start
  checkedC: boolean,
  dialogOpen: boolean,
  tabValue: number,
  deleteDialogOpen: boolean,
  requestNotes: string
  requestAttachments: any,
  customerSelected: boolean
  customersList: any
  requestSource: any
  isTitleEdit: boolean;
  requestForm: {
    title: string
    serviceDetails: string
    requestSource: string
    appointmentDate: any
    appointmentTime: string
    onSite: any
    otherSource:string
    others:string
  };
  all_day:boolean
  searchCustomer: string
  activeId: number,
  customerDetails: Details
  anchorEl: any
  requestDetails: any
  createrequestStatus: boolean,
  siteDailog: boolean
  selectLanguage:string
  customerSitesList: any,
  searchSite: string,
  siteDetails: any,
  customerId: null,
  siteId: any,
  isErrorFormData: any
  internalNotes: string,
  internalAttachments: any,
  getCustomerPlace: any
  defaultLocation: any
  timeFormat: any,
  pdfStatus: boolean,
  savingMode: string,
  requestFormLoading: boolean,
  siteSelected: boolean,
  selectButtonStatus: boolean
  getEmployeeData:object|[],
  teamMember:object[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

const errorData: RequestError[] = [
  { id: 1, formName: 'selectCustomer', errorName: 'customerDetails' },
  { id: 2, formName: 'title', errorName: 'title' },
  { id: 3, formName: 'requestSource', errorName: 'requestSource' }
];
export default class RequestFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdCreateRequest: string = "";
  apiCallIdGetEmployee: string = ""
  apiCallIdConvertedRequest: string = ""
  getSiteDetailsApiCallId: string = ""
  mapRef: any;
  token: string = ""
  getCustomerDetailsApiCallId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    const startDate1 = localStorage.getItem("startDate");
    const calendarView = localStorage.getItem("calendarView");
    const startDate = () => {
      if (startDate1) {
        return new Date(startDate1)
      } else {
        return new Date()
      }
    }
    const appointmentTime = () => {
      if (!calendarView&&startDate1) {
        return moment(startDate1).format('HH:mm A')
      } else if (calendarView) {
        return "09:00 AM"
      } else {
        return "09:00 AM"
      }
    }
    const requestSource = [{ id: 1, sourceName: `${this.props.t('request.sourceMenu.whatsapp')}`, sourceValue: "whatsapp" },
    { id: 2, sourceName: `${this.props.t('request.sourceMenu.instagram')}`, sourceValue: "instagram" },
    { id: 3, sourceName: `${this.props.t('request.sourceMenu.twitter')}`, sourceValue: "twitter" },
    { id: 4, sourceName: `${this.props.t('request.sourceMenu.phoneCall')}`, sourceValue: "phone_call" },
    { id: 5, sourceName: `${this.props.t('request.sourceMenu.email')}`, sourceValue: "email" },
    { id: 6, sourceName: `${this.props.t('request.sourceMenu.others')}`, sourceValue: "others" }]
    this.mapRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      checkedC: true,
      dialogOpen: false,
      tabValue: 0,
      deleteDialogOpen: false,
      requestNotes: "",
      requestAttachments: [],
      customerSelected: false,
      customersList: [],
      requestSource: requestSource,
      isTitleEdit: false,
      selectLanguage:'',
      requestForm: {
        title: "",
        serviceDetails: "",
        requestSource: "",
        appointmentDate:  startDate(),
        appointmentTime: appointmentTime(),
        onSite: false,
        otherSource:"",
        others:"",
      },
      all_day:false,
      internalAttachments: [],
      searchCustomer: "",
      activeId: 0,
      customerDetails: {},
      anchorEl: null,
      requestDetails: [],
      createrequestStatus: false,
      siteDailog: false,
      customerSitesList: [],
      searchSite: "",
      siteDetails: {},
      customerId: null,
      siteId: null,
      isErrorFormData: {},
      internalNotes: "",
      getCustomerPlace: null,
      defaultLocation: {
        lat: 25.276987,
        lng: 55.296249
      },
      timeFormat: "",
      pdfStatus: false,
      savingMode: "",
      requestFormLoading: true,
      siteSelected: false,
      selectButtonStatus: false,
      getEmployeeData:[],
      teamMember:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }
  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    this.getEmployee()
    const fromRoute=await getStorageData('calendarRedirection')
    if (fromRoute) {
      const params = new URLSearchParams(window.location.search);
      params.set('from', 'Calendar');
      window.history.pushState({}, '', `?${params.toString()}`);
    }
    const selectedLanguage = await getStorageData("lang");
    this.setState({ selectLanguage: selectedLanguage })
    localStorage.removeItem('convertType')
    setTimeout(() => {
    this.setState({requestFormLoading:false})
    }, 1000);
    if(this.props.navigation.history?.location?.state?.reDirectionFrom==='customer'){
      this.setState({requestDetails:{...this.state.requestDetails,attributes:{...this.state.requestDetails.attributes,site_id:localStorage.getItem("site_id")}}},
            ()=>{
              this.customerRequestDetails()
              this.getSelectedSiteDetails();
            })
    }
    const authorizatation = localStorage.getItem(configJSON.storageToken) || ""
    this.token = authorizatation
    if (!localStorage.getItem(configJSON.storageToken)) {
      this.props.navigation.history.push("/Login");
    }
    this.setState({
      timeFormat: await getStorageData('timeFormat')
    })
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.apiCallIdCreateRequest) {
        this.createRequestApiResponse(responseJson)
      }
      else if (apiRequestCallId === this.getCustomerDetailsApiCallId) {
        this.ApiResponse(responseJson)
    }
      else if (apiRequestCallId === this.apiCallIdGetEmployee) {
        this.getEmpApiResponse(responseJson)
      } else if (apiRequestCallId === this.getSiteDetailsApiCallId) {
        this.siteInformationApiResponce(responseJson)
    }
    }
    // Customizable Area End
  }
  //customer dailog

  // Customizable Area Start
   onMapLoad = (map: google.maps.Map) => {
    this.mapRef.current = map;
    const zoomInButton = document.createElement('button');
    zoomInButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#1c18af"/>
        <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" fill="#1c18af"/>
    </svg>`;
    zoomInButton.style.backgroundColor = 'white';
    zoomInButton.style.marginTop = '10px';
    zoomInButton.style.marginBottom = '10px';
    zoomInButton.style.padding = '6px 3px 0px 5px';
    zoomInButton.style.borderRadius = '4px';
    zoomInButton.style.border = 'none';
    zoomInButton.style.paddingLeft = '5px';
    zoomInButton.style.cursor = 'pointer';
    zoomInButton.title = 'Zoom In';
    zoomInButton.addEventListener('click', () => map?.setZoom((map?.getZoom() ?? 0) + 1));
    const zoomOutButton = document.createElement('button');
    zoomOutButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path fill="#1c18af" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
    </svg>`;
    zoomOutButton.style.backgroundColor = 'white';
    zoomOutButton.style.marginBottom = '10px';
    zoomOutButton.style.padding = '6px 3px 0px 5px';
    zoomOutButton.style.borderRadius = '4px';
    zoomOutButton.style.paddingLeft = '5px';
    zoomOutButton.style.border = 'none';
    zoomOutButton.style.cursor = 'pointer';
    zoomOutButton.title = 'Zoom Out';
  
    zoomOutButton.addEventListener('click', () =>   map?.setZoom((map?.getZoom() ?? 0) - 1));
  
    const controlDiv = document.createElement('div');
    controlDiv.style.position = 'absolute';
    controlDiv.style.marginRight = '20px';
    controlDiv.style.marginBottom = '20px';
    controlDiv.style.bottom = '100px';
    controlDiv.style.right = '10px';
    controlDiv.style.display = 'flex';
    controlDiv.style.flexDirection = 'column';
    controlDiv.style.zIndex = '1';
    controlDiv.style.justifyContent = 'space-between'; 
    controlDiv.appendChild(zoomInButton);
    controlDiv.appendChild(zoomOutButton);
  
    const controlPosition = google.maps.ControlPosition.RIGHT_BOTTOM;
    map.controls[controlPosition].push(controlDiv);
  }; 
  getSelectedSiteDetails = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSiteDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.siteEndPoint}/${localStorage.getItem("site_id")}?lang=${localStorage.getItem("lang")}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  siteInformationApiResponce = (responseJson:{ errors: { [x: string]: [0]; }; data: []; }) => {
    if (responseJson && !responseJson.errors) {
        this.setState({
            siteDetails: responseJson.data
        }, () => {
            this.saveMapData(this.state.siteDetails)
        })
    }

  }
  createRequestErrorApiResponce = (responseJson: any) => {
    if (responseJson.errors[0].token === configJSON.errorsData.tokenExpired || responseJson.errors[0].token === configJSON.errorsData.invalidToken) {
      this.props.openToastHandler("Session Expired", "error")
      setTimeout(() => {
        localStorage.clear();
        this.props.navigation.history.push('/Login')
      }, 2000);
    }
  }
  getEmpApiResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      this.setState({ getEmployeeData: responseJson.data})
    } else {
      if (responseJson&&responseJson.errors) {
        checkTokenExpired(responseJson,this.props.openToastHandler,this.props.navigation)
        this.setState({ getEmployeeData: [] })
      }
    }
  }
  checkRedirectBack=async()=>{
    const params = new URLSearchParams(window.location.search||'');
    const paramValue = params.get('from');
    if(paramValue){
      this.redirect(paramValue);
    }else{
      this.manageSaveMore()
    }
  }
  createRequestApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ requestFormLoading: false })
      this.setState({ requestDetails: responseJson.data }, async () => {
        await setStorageData(configJSON.requestStatus.created, true)
        this.checkRedirectBack()
      })
    }
    else {
      this.createRequestErrorApiResponce(responseJson)
    }
  }
  handleCheckAllDay = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ all_day: event.target?.checked })
  };
  handleCheckOnSite = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ requestForm: { ...this.state.requestForm, onSite: event.target?.checked } })
  };
  handleChangeTeam = (selected: { value: string; }[]) => {
    this.setState({ teamMember: selected})
  }
  handleRemoveTeam = (employeeId: string) => {
    this.setState({
      teamMember: this.state.teamMember?.filter((item: string|object) => {
        return item !== employeeId
      })
    })
  }
  manageSaveMore = () => {
    if (this.state.savingMode === "saveMoreQuote") {
      this.handleButtonClose()
      localStorage.removeItem('quote_id')
      localStorage.setItem("convertType", "RequestTo")
      this.props.navigation.history.push("/Quote/Create", {
        requestId: this.state.requestDetails?.id,
        convertedType: "RequestTo",
        jobId:this.state.requestDetails.attributes?.job_id,


      })
    } 
  
    else if (this.state.savingMode === "saveMoreJob") {
      localStorage.setItem("convertType", "RequestTo");
    
      const siteId = this.state.requestDetails?.attributes?.site_id;
      localStorage.setItem("site_id", siteId); 
      const customer_id = this.state.requestDetails?.attributes?.customer_id;
      localStorage.setItem("customer_id", customer_id)
      this.handleButtonClose();
    
      this.props.navigation.history.push("/Job/Create", {
        requestId: this.state.requestDetails?.id,
        convertedType: "RequestTo"
      });
    }
  
    else if (this.state.savingMode === "saveMorePdf") {
      this.handleButtonClose()
      this.saveMorePdfOpen()
    }
    else {
      this.props.navigation.history.push("/Request/edit", {
        requestId: this.state.requestDetails?.id,
      })
    }
  }
  getEmployee = async () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const apiEndPoint = configJSON.getEmployeeApiEndPoint
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const getEmployeeApiCall = apiCall({
      header: header,
      httpBody: null,
      url: `${apiEndPoint}`,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.apiCallIdGetEmployee = getEmployeeApiCall.messageId;
    runEngine.sendMessage(getEmployeeApiCall.id, getEmployeeApiCall);
  }
  //istanbul ignore next
  translateToEnglishTime = (arabicTime: string) => {
    const [hour, minute, period] = arabicTime.split(/[ :]/);
    let translatedHour = String(hour);
    let translatedMinute = String(minute);
    
    const arabicNumerals = "`abcdefghi";
    const englishNumerals = "0123456789";
    
    for (let checktime = 0; checktime < arabicNumerals.length; checktime++) {
      translatedHour = translatedHour.replace(new RegExp(arabicNumerals[checktime], 'g'), englishNumerals[checktime]);
      translatedMinute = translatedMinute.replace(new RegExp(arabicNumerals[checktime], 'g'), englishNumerals[checktime]);
    }

    let translatedPeriod = period;
    if (period === "E3'!") {
      translatedPeriod = "PM";
    } else if (period === "5('.'") {
      translatedPeriod = "AM";
    }

    if (period === "E3'!" && parseInt(translatedHour, 10) < 12) {
      translatedHour = String(parseInt(translatedHour, 10) + 12);
    }

    translatedHour = translatedHour.padStart(2, '0');
    translatedMinute = translatedMinute.padStart(2, '0');

    return `${translatedHour}:${translatedMinute} ${translatedPeriod || ""}`;
  };
  createRequest = () => {
    const header = {
      "token": this.token
    };
    this.setState({ requestFormLoading: true })
    
    let bodyDataRequest = new FormData();
    bodyDataRequest.append("data[request_title]", this.state.requestForm.title);
    bodyDataRequest.append("data[customer_id]", this.state.siteDetails.customer_id);
    bodyDataRequest.append("data[site_id]", this.state.siteDetails.id);
    bodyDataRequest.append("data[service_detail]", this.state.requestForm.serviceDetails);
    bodyDataRequest.append("data[source]", this.state.requestForm.requestSource);
    if(this.state.requestForm.requestSource === "others") {
      bodyDataRequest.append("data[other_source]", this.state.requestForm.others);
    }
    bodyDataRequest.append("data[appointment_date]", this.state.requestForm.appointmentDate);
    bodyDataRequest.append("data[appointment_time]", this.translateToEnglishTime(this.state.requestForm.appointmentTime));
    bodyDataRequest.append("data[on_site]", this.state.requestForm.onSite);
    bodyDataRequest.append("data[internal_notes]", this.state.internalNotes);
    bodyDataRequest.append("data[all_day]",this.state.all_day.toString());
    bodyDataRequest.append("data[employee_ids]", this.state.teamMember.toString());
    
    for (let file of this.state.internalAttachments) {
      bodyDataRequest.append("data[files][]", file);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdCreateRequest = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createRequest}?lang=${this.state.selectLanguage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), bodyDataRequest
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleOpenDialog = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("request");
    this.handleOpenDialogCallback(isAuthorized)

  }
  handleOpenDialogCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({ dialogOpen: true });
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  }


  saveMapData = (customer: any) => {
    const selectedPlace = {
      lat: parseFloat(customer.latitude),
      lng: parseFloat(customer.longitude),
    };
    this.setState({ getCustomerPlace: selectedPlace });

    const map = this.mapRef.current;
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(selectedPlace);
      map.fitBounds(bounds, 100);
      map.setZoom(15);
    }

  }
  handleButton = (event: any) => {
    this.setState({ anchorEl: event&&event.currentTarget })
  }

  handleButtonClose = () => {
    this.setState({ anchorEl: null })
  };
  handleRequestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ requestForm: { ...this.state.requestForm, [event.target.name]: event.target.value } },
      ()=>{
        this.setState({ isErrorFormData: {...this.state.isErrorFormData,...this.requestTitleValidation()} })
      });
  }
  handleOnBlur = () => {
    this.setState({ isErrorFormData: { ...this.state.isErrorFormData, ...this.requestTitleValidation() } })
  }
  updateInternalNotes = (internalNotes: string) => {
    this.setState({ internalNotes: internalNotes })
  }
  updateInternalAttachments = (attachments: any) => {
    this.setState({ internalAttachments: attachments })
  }
  // validations code
  clean(obj: { [x: string]: any; title?: string; }) {
    for (let propName in obj) {
        if (obj[propName]?.length === 0) {
            delete obj[propName];
        }
    }
    return obj
}
  handleCusValidation = () => {
    this.setState({ isErrorFormData: { ...this.state.isErrorFormData, ...this.requestCusValidation() } })
  }
  handleSourceOnBlur = () => {
    this.setState({ isErrorFormData: { ...this.state.isErrorFormData, ...this.requestSourceValidation() } })
  }
  requestTitleValidation = () => {
    const errors = { title: "" };
    this.validateTitle(this.state.requestForm.title, errors)
    this.clean(errors)
    return errors;
  }
  requestSourceValidation = () => {
    const errors = { requestSource: "" };
    if (!this.state.requestForm.requestSource) {
      errors.requestSource = `${this.props.t('request.errorText.sourceErrorText')}`
    }
    this.clean(errors)
    return errors;
  }
  requestCusValidation = () =>{
    const errors = { title: "" };
    this.validateCustomer(this.state.customerDetails, this.state.siteDetails, errors)
    this.clean(errors)
    return errors;
  }
  validateForm = (values: any, customerDetails: any, siteDetails: any) => {
    const errors: any = {};
    this.validateTitle(values.title, errors)
    this.validateServiceDetails(values.title, errors)
    this.validateCustomer(customerDetails, siteDetails, errors)
    if (!values.requestSource) {
      errors.requestSource = `${this.props.t('request.errorText.sourceErrorText')}`
    }
    if (values.appointmentDate && values.appointmentDate < dateMinusOne(new Date())) {
      errors.appointmentDate= `${this.props.t('Calendar.ErrorMessage.minDate')}`;
    }
    if (this.state.requestForm.requestSource === "others" && !values.others) {
      errors.others = `${this.props.t('request.errorText.otherErrorText')}`
    }
    return errors;
  }

  validateCustomer = (customerDetails: any, siteDetails: any, errors: any) => {
    if (Object.keys(customerDetails).length === 0 && Object.keys(siteDetails).length === 0) {
      errors.customerDetails = `${this.props.t('errorMessage.customerId')}`
    } else if (Object.keys(siteDetails).length === 0) {
      errors.customerDetails = `${this.props.t('errorMessage.siteId')}`
    }
  }
  validateServiceDetails = (service: string, errors: any) => {
    if (!service) {
      errors.service = `${this.props.t('request.errorText.sourceErrorText')}`
    } else if ((service).length > 500) {
      errors.title = `${this.props.t('request.errorText.serviceCharacterslengthText')}`
    }
  }
  validateTitle = (title: any, errors: any) => {
    if (!title) {
      errors.title = `${this.props.t('request.errorText.titleEmptyText')}`
    } else if ((title).length > 40) {
      errors.title = `${this.props.t('request.errorText.titleCharacterslengthText')}`
    }
  }
 
  redirect = (url: string) => {
    if (url) {
      this.clearRedirect()
      navigateTo({
        props: this.props,
        screenName: "Calendar",
      })
    }else{
      this.manageSaveMore()
    }
  };
  clearRedirect = async () => {
    await removeStorageData("selectedEventId");
    await removeStorageData("type");
    await removeStorageData("startDate");
    await removeStorageData("calendarView")
    await removeStorageData("calendarRedirection");
  };
  //istanbul ignore next
  saveRequestbtn = (status: string): void => {
    this.setState({
        savingMode: status,
        isErrorFormData: this.validateForm(this.state.requestForm, this.state.customerDetails, this.state.siteDetails)
    }, async () => {
        const { isErrorFormData } = this.state;

        if (Object.keys(isErrorFormData).length === 0) {
            this.createRequest();
        } else {
           
            const errorKeys = Object.keys(isErrorFormData);

            for (const errorKey of errorKeys) {              
                
                const matchedError = errorData.find((error: RequestError) => error.errorName === errorKey);

                if (matchedError) {
                 
                    const element = document.querySelector(`[name="${matchedError.formName}"]`);                 

                    if (element)
                       {                      
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        break;
                    }
                }
            }
        }
    });
}


  cancelCreateRequest = () => {
    this.props.navigation.history.push("/Requests")
  }
  getCustomerDailogStatus1 = (selected: boolean) => {
    this.setState({
      selectButtonStatus: selected,
    })
  }

  getSiteSelectedStatus = (siteSelected: boolean) => {
    this.setState({ siteSelected: siteSelected })
  }
  getCustomerDetails = (details: any) => {
    this.setState({
      customerDetails: details
    })
  }
  getSiteDetails = (details: any) => {
    this.setState({
      siteDetails: details
    })
  }
  handleChangeHours = (timeValue: any) => {
    this.setState({
      requestForm: { ...this.state.requestForm, appointmentTime: timeValue }
    })

  }
  saveMorePdfOpen = () => {
    this.setState({ pdfStatus: true })
  }
  saveMorepdfClose = () => {
    this.setState({ pdfStatus: false }, () => {
      this.props.navigation.history.push("/Request/edit", {
        requestId: this.state.requestDetails?.id,
      })
    })

  }
  
  getcurentLocation = () => {
    const googleMapsUrl = `https://www.google.com/maps?q=${this.state.getCustomerPlace.lat},${this.state.getCustomerPlace.lng}&z=15&markers=${this.state.getCustomerPlace.lat},${this.state.getCustomerPlace.lng}`;
    window.open(googleMapsUrl, '_blank');
  }
  clearCustomer = () => {
    this.setState({
      dialogOpen: false,
      customerSelected: false,
      customerDetails: {},
      siteDetails: {},
      selectButtonStatus: false,
      getCustomerPlace: null
    },()=>{
      this.setState({ isErrorFormData: {...this.state.isErrorFormData,...this.requestCusValidation()} })
    })
  }
  getCustomerSelectedStatus = (customerSelected: boolean, customerDailog: boolean) => {
    this.setState({
      customerSelected: customerSelected,
      dialogOpen: customerDailog,

    })
  }

  handleDateChange=(date:any)=>{
    this.setState({requestForm: { ...this.state.requestForm, appointmentDate: date }});
}

customerRequestDetails = () => {
  const token = localStorage.getItem(configJSON.storageToken)
  const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCustomerDetailsApiCallId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomerDetails}?id=${this.props.navigation.history.location.state.id}&lang=${this.state.selectLanguage}`
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

ApiResponse = (responseJson: any) => {
  if (responseJson && !responseJson.errors) {
    this.setState({ requestFormLoading: false })
    this.setState({
      customerDetails: responseJson.data,
      selectButtonStatus:true
    })
  }
  
}

  // Customizable Area End
}
