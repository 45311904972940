//@ts-nocheck
import React from 'react';
import {
    Dialog, DialogTitle,Box, DialogContent,FormHelperText,Checkbox, withStyles, Button, Typography, Grid, DialogActions,  Divider
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { StyledTextFieldQuote } from '../../RequestManagement/src/Utility.web';
import FileUpload from '../../../components/src/FileUpload.web';
import { uploadIcon } from '../../RequestManagement/src/assets';
import { CONSTANTS } from "../../../components/src/theme"
import { t } from "i18next";
export const EmailModalStyle = (theme:any) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#000000',
        width: "15px",
        height: "16px",
    },
    fontWeight_600:{
        fontWeight:CONSTANTS.fontWeight.fontWeight600
    },
    text_lightBrown: {
        color: CONSTANTS.color.lightBrown
    },
    sendBtn: {
        borderRadius: "8px",
        background: CONSTANTS.color.darkBlue,
        width: "166px",
        height: "56px",
        transition: 'background-color 0.3s, color 0.3s',
        color: CONSTANTS.color.white,
        textTransform: "capitalize",
        '&:hover': {
            backgroundColor: CONSTANTS.color.blueViolet,
            color: theme.palette.common.white,
            cursor: "pointer"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        },
    },
    btnCancel: {
        borderRadius: "8px",
        background: CONSTANTS.color.white,
        width: "166px",
        height: "56px",
        textTransform: "capitalize",
        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
        '&:hover': {
          backgroundColor: '#999999',
          cursor: "pointer",
          color: theme.palette.common.white
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    btnParent:{
        [theme.breakpoints.down("xs")]: {
            gap: "0.5rem",
        },
    },
    startAdornment: {
        padding: "0.5rem",
        background: "rgba(153,153,153,0.1)",
        marginLeft: 0,
        borderRadius: "8px 8px 0px 0px",
        width: "120px",
        height: "56px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    subjectInput: {
        "& .MuiInputBase-root": {
            borderRadius: "8px 8px 0px 0px",
        }
    },
    messageInput: {
        "& .MuiInputBase-root": {
            borderRadius: " 0px 0px 8px 8px",
        }
    },
    uploadFilesData: {
        width: "100%",
        height: "100%",
        minHeight: "100px",
       paddingLeft:'10px',
       paddingRight:'10px',
        borderRadius: "8px",
        border: "1px solid rgba(0,0,0,0.1)"
    },
    upload: {
        border: "2px dashed #1c18af",
        background: "rgba(28, 24, 175, 0.05)",
        color:  CONSTANTS.color.darkBlue,
        fontSize: "12px",
        fontWeight: 400,
        textTransform: "capitalize",
        borderRadius: "8px",
        padding: "12px",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
        "&:hover": {
            background: "rgba(28, 24, 175, 0.05)",
        }
    },
    EmailUploadFile: {
        border: "2px dashed #1c18af",
        height: "56px",
        width: "100%",
        background: "rgba(28, 24, 175, 0.05)",
        color: CONSTANTS.color.darkBlue,
        fontSize: "12px",
        fontWeight: 400,
        textTransform: "capitalize",
        borderRadius: "8px",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },
        "&:hover": {
            background: "rgba(28, 24, 175, 0.05)",
        }
    },
    paddingRight_10:{
        paddingRight:'10px',
        [theme.breakpoints.down("sm")]: {
            paddingRight:'0px',
        },
    },
    paddingLeft_10:{
        paddingLeft:'10px',
        [theme.breakpoints.down("sm")]: {
            paddingLeft:'0px',
        },
    },
    uploadText: {
        fontSize: "12px",
        fontWeight: 400,
        color: "#4c4c4c"
    },
    uploadDataGrid: {
        display: "flex",
        justifyContent: "center"
    },
    errorText: {
        marginLeft: "5px",
        color: CONSTANTS.color.crimsonRed,
      },
    uploadFileBox: {
        width: "100%",
        margin: 0,
        display: "flex",
        justifyContent: "flex-end"
    },
    imageBoxEmail: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    imageBoxEmailV1: {
        marginRight: "9px",
    },
    iconUploadSize: {
        width: "60px",
        height: "60px",
    },
    fileNameBox: {
        display: "flex",
        flexDirection: "column",
    },
    fileNameTitle: {
        color: CONSTANTS.color.grey,
        fontSize: "20px",
        fontWeight: 400,
        marginBottom: 0,
    }
   
});
const Accordion = withStyles({
    root: {
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      marginBottom: -11,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
      '&.MuiAccordionSummary-content':{
        margin: '0px 0px !important',
      },
      '&.MuiAccordionSummary-root':{
        padding:'0px'
      },
      '&.MuiAccordionSummary-root.Mui-focused , MuiAccordionSummary-root.Mui-focusVisible':{
        background:CONSTANTS.color.white
      }
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  

export class EmailModal extends React.Component {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        this.state={
            expanded:'',
                email: this.props.customerDetails.attributes.email || "",
        
        }
        // Customizable Area End
    }

    // Customizable Area Start
     handleChange = (panel:any) => (event, newExpanded) => {
        this.setState({
            expanded: newExpanded ? panel : false })
        
      };
      EditEmail=(e:any)=>{
        
        const updatedEmail = e.target.value;
         this.setState({ email: updatedEmail }); 
           //istanbul ignore next
         if (this.props.handleEmailChange) {
         this.props.handleEmailChange(e); 
        }
      }
      accordionContent({classes}){
         return <div className={classes.uploadFilesData}>
              <Grid container direction="row" alignItems='center' style={{ marginTop: "15px" }} spacing={1}>
                  <Grid item xs={12} md={6} className={classes.uploadDataGrid}>
                      <Typography className={classes.uploadText}>{t('emailModal.dragFileText')}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.uploadDataGrid}>
                          <Grid item xs={12}>
                              <Button
                                  variant="outlined"
                                  component="label"
                                  disableRipple
                                  disableElevation
                                  disableFocusRipple
                                  className={classes.EmailUploadFile}
                              >
                                  <img src={uploadIcon} width="20px" height={"20px"} style={{ marginRight: "12px" }} />
                                  <input
                                      hidden
                                      type="file"
                                      multiple
                                      name='files'
                                      onChange={this.props.handleEmailFileChange}
                                  />
                                 {t('emailModal.uploadFile')}
                              </Button>
                              {this.props.progress > 0 && (
                                  <FileUpload
                                      {...this.props}
                                      progress={this.props.progress}
                                      t={t}
                                      languageSelected={this.props.languageSelected}
                                  />
                              )}
                          </Grid>
                  </Grid>
                 <Grid item xs={12}>
                     <>
                         {this.props.completed && this.props.files && this.props.files.length > 0 && this.props.files.map((file: any) => {
                             return (
                                 <>
                                 <Divider/>
                                     <Box key={file?.name}>
                                         <Box className={classes.uploadFileBox}>
                                             <ClearIcon
                                             onClick={this.props.handleRemoveFile.bind()}
                                             />
                                         </Box>
                                         {/* image section */}
                                         <Box className={classes.imageBoxEmail}>
                                             <Box>
                                                 <Checkbox disabled
                                                     style={{ color: CONSTANTS.color.darkBlue }}
                                                 />
                                             </Box>
                                             <Box className={classes.imageBoxEmailV1}
                                             onClick={this.props.handleDownloadFile.bind(file)}
                                             >
                                                 <img
                                                     className={classes.iconUploadSize}
                                                     src={
                                                         file?.name && this.props.extensionIconsEmail[file.name.split(".")[1]]
                                                     }
                                                     alt="upload"
                                                 />
                                             </Box>
                                             {/* text section */}
                                             <Box className={classes.fileNameBox}>
                                                 <Box>
                                                     <Typography
                                                         gutterBottom
                                                         component="div"
                                                         className={classes.fileNameTitle}
                                                     >
                                                         {file?.name && file.name.length > 15
                                                             ? file.name
                                                                 .split(".")[0]
                                                                 .concat(".")
                                                                 .concat(file.name.split(".")[1])
                                                                 .substring(0, 15) + "..."
                                                             : file?.name}
                                                     </Typography>
                                                 </Box>
                                                 <Box>
                                                     <Typography
                                                         gutterBottom
                                                         className={{ ...classes.fileNameTitle, color:CONSTANTS.color.grey, fontSize: "14px" }}
                                                     >
                                                         {Math.floor(file?.size / 1024)}kb
                                                     </Typography>
                                                 </Box>
                                             </Box>
                                         </Box>
                                     </Box>
                                 </>
                             )
                         })}
                     </>
                 </Grid>
              </Grid>
          </div>
      }
    // Customizable Area End

    render() {
        const { classes } = this.props
        return (
            // Customizable Area Start
            
            <Dialog
                maxWidth="lg"
                open={this.props.open}
                onClose={this.props.handleClose}
                PaperProps={{
                    style: {
                        width: "880px",
                        backgroundColor:CONSTANTS.color.white,
                        boxShadow: '2px 1px 10px 0 rgba(0, 0, 0, 0.15)',
                        borderRadius: 10,
                    },
                }}>
                <DialogTitle>
                    <Grid item xs={11}>
                    <Typography variant='h6'className={`${classes.fontWeight_600} ${classes.text_lightBrown}`}>{t('emailModal.emailInvoice')}{this.props.createdInvoiceData?.id} {t('emailModal.to1')}  {this.props.customerDetails?.attributes?.name}</Typography>
                    </Grid> 
                    <Grid item xs={1}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.onClose}>
                        <CloseIcon />
                    </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12} style={{ marginTop: "1rem" }}>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item xs={12} sm={6} className={classes.paddingRight_10}>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={12}>
                                        <StyledTextFieldQuote
                                            disabled={false}
                                            value={this.state.email}
                                            placeholder={t('BoAuth.emailPlaceHolder')}
                                            InputProps={{
                                                startAdornment: <div className={classes.startAdornment}>{t('emailModal.to')}</div>
                                            }}
                                            variant="outlined"
                                            name="to"
                                            data-test-id = "editEmail"
                                            onChange={(e) => {
                                                this.EditEmail(e)
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <StyledTextFieldQuote
                                            InputProps={{
                                                startAdornment: <div className={classes.startAdornment}>{t('emailModal.subject')}</div>
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            name="subject"
                                            value={this.props.EmailData.subject}
                                            onChange={this.props.handleEmailChange}
                                            className={classes.subjectInput}
                                        />
                                        <StyledTextFieldQuote
                                            placeholder=''
                                            className={classes.messageInput}
                                            variant="outlined"
                                            onChange={this.props.handleEmailChange}
                                            fullWidth
                                            name={"body"}
                                            value={this.props.EmailData.body}
                                            multiline
                                            minRows={7}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={5} className={classes.paddingLeft_10}>
                                <Grid container justifyContent='space-between'>
                                    <Grid item>
                                    <Divider orientation="vertical"/>
                                    </Grid>
                                    <Grid item style={{ width: "100%"}}>
                                        <Typography variant='subtitle1'>{t('emailModal.attachments')}</Typography>
                                        <Accordion square expanded={this.state.expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                                            <AccordionSummary className={{ margin: '0px' }} aria-controls="panel1d-content" id="panel1d-header">
                                                {this.accordionContent({classes})}
                                            </AccordionSummary>
                                        </Accordion>
                                        <Accordion square expanded={this.state.expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                                            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                                <Grid item xs={12}>
                                                    <StyledTextFieldQuote
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.attachment}
                                                        value={`${t('emailModal.invoiceAttachment')} (${this.props.completed? 1:0})`}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </AccordionSummary>
                                            
                                        </Accordion>
                                        <Accordion square   expanded={this.state.expanded === 'panel3'} onChange={this.handleChange('panel3')}>
                                            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                                <Grid item xs={12}>
                                                    <StyledTextFieldQuote
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.attachment}clientAttachments
                                                        value={`${t('emailModal.clientAttachment')} (${this.props.clientAttachments.length})`}
                                                        defaultValue='Client Attachments'
                                                    />
                                                </Grid>
                                            </AccordionSummary>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                          
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{padding:'16px 24px'}}>
                    <Grid item xs={12}>
                        <Grid container direction='row' className={classes.btnParent}>
                            <Grid item xs={12} sm={6}>
                                <Button className={classes.btnCancel} variant='outlined' onClick={this.props.onClose}>
                                    <Typography variant='body1'>{t('invoice.button.cancel')}</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} container justifyContent='flex-end'>
                                <Button variant='outlined' data-test-id = "sendEmail" onClick={()=>this.props.handleClose(this.state.email)} className={classes.sendBtn}>
                                    <Typography variant='body1'>{t('emailModal.button.sendEmail')}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            // Customizable Area End
        );
    }
}
export default withStyles(EmailModalStyle)(EmailModal);
