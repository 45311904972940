import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall, checkTokenExpired ,dateMinusOne, navigateTo} from '../../../components/src/CommonFunction'
import { checkLoggedInUserDashboard, } from "../../dashboard/src/utility.web";
import moment from "moment";
import { performInvoiceAuthorizationCheck } from "../../CustomisableUserProfiles/src/utility.web";
import { getStorageData } from "../../../framework/src/Utilities";
interface CreateTask {
  title: string,
  employee_id: string,
  due_date: string,
  urgency: string,
  email_team: string
}
interface CreateJob {
  title: string,
  employee_id: string,
  start_date: string,
  end_date: string,
  description: string,
  start_time: string,
  end_time: string,
  customer_id: string,
  site_id: string
  all_day: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler: any
  history: any
  t: (key: string) => string;
  classes: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  events: any
  taskEvents: any
  appointmentForm: any,
  vertical: any
  horizontal: any
  openSlot: boolean
  openEvent: boolean
  openDeleteAppointmentModal: boolean
  openEditAppointmentModal: boolean
  openNewAppointmentModal: boolean
  openMoreActionMenu: boolean
  openAssignToMenu: boolean
  openTaskModal: boolean
  openJobModal: boolean
  openEditTaskModal: boolean
  openEditJobModal: boolean
  clickedEvent: any
  selectedEventData: any
  view: any
  title: string
  start: string,
  end: string,
  startDate: Date
  datePickerIsOpen: boolean
  isOpenNewAppointment: boolean
  openDeleteTaskModal: boolean
  isDeleteAppointment: boolean
  isDeleteJob: boolean
  isOpenNewTask: boolean
  isOpenNewJob: boolean
  selectedEmployee: string
  getEmployeeData: any
  getAppointmentData: object
  createAppointmentData: object
  createTaskData: object
  updateTaskData: object
  createJobData: object
  updateJobData: object
  getCustomerData: any
  getSiteData: object
  getAppointmentLoading: boolean
  onOffRange: any
  markAsComplete: any
  checkEmployee: boolean
  isActive: boolean
  viewAppointmentDetails: boolean
  viewTaskDetails: boolean
  viewJobDetails: boolean
  isEmployee: any
  employee_id: any
  selectedDate: any
  selectLanguage: string
  projectDetailModal: boolean;
  selectedProjectDate: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start

  // Customizable Area End
}

export default class CalendarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentApiCallId: string = ''
  deleteAppointmentApiCallId: string = ''
  updateAppointmentStatusApiCallId: string = ''
  getEmployeeApiCallId: string = ''
  createTaskApiCallId: string = ''
  updateTaskApiCallId: string = ''
  getCustomerApiCallId: string = ''
  getSiteApiCallId: string = ''
  createJobApiCallId: string = ''
  updateJobApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      events: [],
      taskEvents: [],
      appointmentForm: {
        title: "",
        start: '',
        end: '',
        desc: "",
      },
      openSlot: false,
      openEvent: false,
      title: '',
      start: '',
      end: '',
      onOffRange: {},
      openMoreActionMenu: false,
      openAssignToMenu: false,
      openTaskModal: false,
      openJobModal: false,
      isActive: false,
      getCustomerData: [],
      getSiteData: [],
      openEditTaskModal: false,
      openEditJobModal: false,
      openDeleteAppointmentModal: false,
      openEditAppointmentModal: false,
      openNewAppointmentModal: false,
      clickedEvent: {},
      selectedEventData: {},
      checkEmployee: false,
      view: 'month',
      vertical: 'top',
      horizontal: 'center',
      isOpenNewAppointment: true,
      openDeleteTaskModal: false,
      isDeleteAppointment: false,
      isDeleteJob: false,
      isOpenNewTask: true,
      isOpenNewJob: true,
      selectedEmployee: '',
      getEmployeeData: [],
      getAppointmentData: [],
      createAppointmentData: [],
      createTaskData: [],
      updateTaskData: [],
      createJobData: [],
      updateJobData: [],
      getAppointmentLoading: true,
      startDate: new Date(),
      datePickerIsOpen: false,
      markAsComplete: false,
      viewAppointmentDetails: false,
      viewTaskDetails: false,
      viewJobDetails: false,
      isEmployee: null,
      employee_id: null,
      selectedDate: '',
      selectLanguage: '',
      projectDetailModal: false,
      selectedProjectDate: ''
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const employee_Id = localStorage.getItem("employeeId");
    const selectedLanguage = await getStorageData("lang");
    this.setState({ employee_id: employee_Id, selectLanguage: selectedLanguage })
    const user = await checkLoggedInUserDashboard();
    this.setState({ isEmployee: user });
    this.getAppointment()
    this.getEmployee()
    this.getCustomer()
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getAppointmentApiCallId) {
        this.getAppointmentResponse(responseJson)
      } else if (apiRequestCallId === this.deleteAppointmentApiCallId) {
        this.deleteAppointmentResponse(responseJson)
      } else if (apiRequestCallId === this.updateAppointmentStatusApiCallId) {
        this.updateAppointmentStatusResponse(responseJson)
      } else if (apiRequestCallId === this.getEmployeeApiCallId) {
        this.getEmployeeResponse(responseJson)
      } else if (apiRequestCallId === this.createTaskApiCallId) {
        this.createTaskResponse(responseJson)
      } else if (apiRequestCallId === this.updateTaskApiCallId) {
        this.updateTaskResponse(responseJson)
      } else if (apiRequestCallId === this.getCustomerApiCallId) {
        this.getCustomerResponse(responseJson)
      } else if (apiRequestCallId === this.getSiteApiCallId) {
        this.getSiteResponse(responseJson)
      } else if (apiRequestCallId === this.createJobApiCallId) {
        this.createJobResponse(responseJson)
      } else if (apiRequestCallId === this.updateJobApiCallId) {
        this.updateJobResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleViewChange = (view: any) => {
    this.setState({ view: view })
    this.getAppointment();
  }
  handleMonthViewChange = (view: any) => {
    this.setState({ view: view })
  }
  handleDayViewChange = (view: any) => {
    this.setState({ view: view })
  }
  assignToValue = (event: any) => {
    this.setState({
      selectedEventData: {
        ...this.state.selectedEventData,
        employee: event.attributes?.employees?.map((item: any) => {
          return {
            employee_name: item.name,
            employee_id: item.id,
            employee_email: item.email
          }
        }),
        employee_img: event.attributes?.employee_image?.map((item: any) => {
          return {
            employee_image: item?.url
          }
        }),
      }
    })
  }
  taskEmp = (event: any) => {
    this.setState({
      selectedEventData: {
        ...this.state.selectedEventData,
        employee_name: event.attributes && event.attributes.employee?.name,
        employee_id: event.attributes && event.attributes.employee?.id,
        employee_email: event.attributes && event.attributes.employee?.email,
        employee_image: event.attributes && event.attributes.employee_image?.url || ''
      }
    })
  }
  evenTitle = (event: any) => {
    if (event.type === 'appointment') {
      return event.attributes && event.attributes.title
    } else if (event.type === 'task') {
      return event.attributes && event.attributes.task_name
    } else {
      return event.attributes && event.attributes.job_title
    }
  }
  handleEventSelected = (event: any) => {
    this.setState({
      openEvent: true,
      clickedEvent: event,
      selectedEventData: {
        start: moment.utc(`${event.attributes?.start_date} ${event.attributes?.start_time}`).toDate(),
        end: moment.utc(`${event.attributes?.end_date} ${event.attributes?.end_time}`).toDate(),
        title: this.evenTitle(event),
        description: event.attributes && event.attributes.description || '',
        start_date: event.attributes && event.attributes.start_date,
        end_date: event.attributes && event.attributes.end_date,
        start_time: event.attributes && event.attributes.start_time,
        end_time: event.attributes && event.attributes.end_time,
        id: event.id,
        email_team: event.attributes && event.attributes.email_team,
        mark_as_complete: event.attributes && event.attributes.mark_as_complete,
        all_day: event.attributes && event.attributes.all_day,
        urgency: event.attributes && event.attributes.urgency,
        due_date: event.attributes && event.attributes.due_date,
        customer_id: event.attributes?.customer_id,
        site_id: event.attributes?.site_id,
        type: event.type,
        request_id: event.attributes && event.attributes.request_id
      }
    }, () => {
      if (event.type === 'appointment') {
        this.assignToValue(event)
      } else if (event.type === 'job') {
        this.assignToValue(event)
      } else {
        this.taskEmp(event)
      }
      this.getSite(event.attributes?.customer_id)
    })
  }
  selectMonthViewSlot=(start:Date)=>{
    if(this.state.view==='month'){
      if (moment(start).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
        this.setState({ openSlot: true })
      } else {
        this.setState({ openSlot: false })
        this.props.openToastHandler(`${this.props.t('Calendar.ErrorMessage.minDate')}`, "error");
      }
    }
  }
  selectWeekViewSlot=(start:Date)=>{
    if(this.state.view==='week'){
      if (moment(start).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
        this.setState({ openSlot: true });
      }
    }
  } 
  
  
  selectDayViewSlot=(start:Date)=>{
    if(this.state.view==='day' || this.state.view==='week'){
      if (moment(start).format('HH:mm') >= moment().format('HH:mm')) {
        this.setState({ openSlot: true });
      } else {
        this.setState({ openSlot: false })
        this.props.openToastHandler(`${this.props.t('Calendar.ErrorMessage.minTime')}`, "error");
      }
    }
  }
  handleSlotSelected = (slotInfo: any) => {
    let dateObj = new Date(slotInfo.end);
    this.setState({
      appointmentForm: {
        start: slotInfo.start,
        end: dateObj.toString(),
        title: '',
        desc: '',
      },
    });
    this.selectMonthViewSlot(slotInfo.start)
    this.selectWeekViewSlot(slotInfo.start)
    this.selectDayViewSlot(slotInfo.start)
  }
  handleStatusChange = async (event: any) => {
    const checkboxEvent = event.target?.checked
    const isAuthorized = await performInvoiceAuthorizationCheck("scheduling"); //true = not authorized , false = authorized
    this.handleStatusChangeCallback(isAuthorized, checkboxEvent);
  };
  handleStatusChangeCallback = (isAuthorized: any, checkboxEvent: any) => {
    if (!isAuthorized) {
      this.updateAppointmentStatus(checkboxEvent)
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  };
  handleChange = (employeeData: any) => {
    this.state.getEmployeeData.forEach((item: any) => {
      if (item.id === employeeData.id) {
        item.attributes.isSelected = !item.attributes.isSelected
        return item
      }
    })
    this.getAppointment()
  }
  handleClose = () => {
    this.setState({ openEvent: false, openSlot: false });
  }
  handleOpenMoreActionMenu = (event: any) => {
    this.setState({ openMoreActionMenu: event.currentTarget })
  }
  handleCloseMoreActionMenu = () => {
    this.setState({ openMoreActionMenu: false });
  }
  handleOpenDeleteAppointMentModal = async () => {
    if (this.state.selectedEventData.type === 'appointment') {
      this.setState({ openDeleteAppointmentModal: true, openDeleteTaskModal: true, isDeleteAppointment: false, isDeleteJob: false, openNewAppointmentModal: false, openEvent: false, openMoreActionMenu: false });
    } else if (this.state.selectedEventData.type === 'task') {
      this.setState({ openDeleteAppointmentModal: true, openDeleteTaskModal: false, isDeleteAppointment: true, isDeleteJob: false, openNewAppointmentModal: false, openEvent: false, openMoreActionMenu: false });
    } else {
      this.setState({ openDeleteAppointmentModal: true, openDeleteTaskModal: false, isDeleteAppointment: false, isDeleteJob: true, openNewAppointmentModal: false, openEvent: false, openMoreActionMenu: false });
    }
  };
  handleCloseDeleteAppointMentModal = () => {
    this.setState({ openDeleteAppointmentModal: false });
  }
  handleOpenEditAppointMentModal = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("scheduling"); //true = not authorized , false = authorized
    this.handleEditAppointmentCallback(isAuthorized);
  };
  handleEditAppointmentCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      if (this.state.selectedEventData.type === 'appointment') {
        localStorage.setItem("selectedEventId",this.state.selectedEventData.request_id)
        localStorage.setItem("calendarRedirection",this.props.navigation.history.location.pathname)
        localStorage.setItem('type','edit')
        navigateTo({
          props: this.props,
          screenName: "RequestEdit",
        });   
      } else if (this.state.selectedEventData.type === 'task') {
        localStorage.setItem("selectedEventId", this.state.selectedEventData.id)
        localStorage.setItem("calendarRedirection", this.props.navigation.history.location.pathname)
        localStorage.setItem('type', 'edit')
        navigateTo({
          props: this.props,
          screenName: "TaskAllocator",
        }); 
      } else {
        localStorage.setItem("selectedEventId",this.state.selectedEventData.id)
        localStorage.setItem("calendarRedirection",this.props.navigation.history.location.pathname)
        localStorage.setItem('type','edit')
        navigateTo({
          props: this.props,
          screenName: "JobEdit",
        }); 
      }
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  };
  handleOpenViewAppointMentModal = () => {
    if (this.state.selectedEventData.type === 'appointment') {
      this.setState({ viewAppointmentDetails: true, isOpenNewAppointment: false, openNewAppointmentModal: true, openEditAppointmentModal: false, openEvent: true, openMoreActionMenu: false });
    } else if (this.state.selectedEventData.type === 'task') {
      this.setState({ viewTaskDetails: true, openEditTaskModal: false, openTaskModal: true, openEvent: false, openMoreActionMenu: false, isOpenNewTask: false });
    } else {
      this.setState({ viewJobDetails: true, openEditJobModal: false, openEvent: false, openMoreActionMenu: false, isOpenNewJob: false, openJobModal: true });
    }
  }
  handleOpenAssignMenu = () => {
    this.setState({ openAssignToMenu: true });
  }
  handleCloseAssignMenu = () => {
    this.setState({ openAssignToMenu: false });
  }

  handleOpenTask = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("scheduling"); //true = not authorized , false = authorized
    this.handleNewTaskCallback(isAuthorized);
  };
  handleNewTaskCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      localStorage.setItem("calendarRedirection", this.props.navigation.history.location.pathname)
      localStorage.setItem("startDate", this.state.appointmentForm.start)
      navigateTo({
        props: this.props,
        screenName: "TaskAllocator",
      }); 
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  };
  handleOpenJob = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("scheduling"); //true = not authorized , false = authorized
    this.handleNewJobCallback(isAuthorized);
  };
  handleNewJobCallback = (isAuthorized: boolean) => {
    const endDate:Date = dateMinusOne(this.state.appointmentForm.end)
    if (!isAuthorized) {
      localStorage.setItem("calendarRedirection", this.props.navigation.history.location.pathname)
      localStorage.setItem("startDate", this.state.appointmentForm.start)
      if(this.state.view==='month'){
        localStorage.setItem("endDate", endDate.toString())
      }else{
        localStorage.setItem("endDate", this.state.appointmentForm.end)
      }
      if(this.state.view==='month'){
        localStorage.setItem('calendarView', this.state.view)
      }
      navigateTo({
        props: this.props,
        screenName: "CreateJob",
      })
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  };
  handleCloseJob = () => {
    this.setState({ openJobModal: false, openSlot: false, openEditJobModal: false });
    if (this.state.viewJobDetails) {
      this.setState({ viewJobDetails: false })
    }
  }
  handleCloseTask = () => {
    this.setState({ openTaskModal: false, openSlot: false, openEditTaskModal: false });
    if (this.state.viewTaskDetails) {
      this.setState({ viewTaskDetails: false })
    }
  }
  handleOpenAppointment = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("scheduling"); //true = not authorized , false = authorized
    this.handleNewAppointmentCallback(isAuthorized);
  };
  handleNewAppointmentCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      localStorage.setItem("calendarRedirection", this.props.navigation.history.location.pathname)
      localStorage.setItem("startDate", this.state.appointmentForm.start)
      if(this.state.view==='month'){
        localStorage.setItem('calendarView', this.state.view)
      }
      navigateTo({
        props: this.props,
        screenName: "RequestForm",
      })
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  };
  handleCloseNewAppointMentModal = () => {
    this.setState({ openEvent: false, openNewAppointmentModal: false, openSlot: false, selectedEventData: {} });
    if (this.state.isOpenNewAppointment) {
      this.setState({ openNewAppointmentModal: false })
    } else {
      this.setState({ openEditAppointmentModal: false, isOpenNewAppointment: true });
    }
    if (this.state.viewAppointmentDetails) {
      this.setState({ viewAppointmentDetails: false })
    }
  }
  handleChangeEmployee = (selected: any) => {
    this.setState({ selectedEmployee: selected.value })
  }
  handleDelete = () => {
    this.deleteAppointment()
  }
  handleMonthChange = (date: Date) => {
    this.setState({ startDate: date }, () => {
      this.setState({ datePickerIsOpen: false })
    })
  }
  handleDayChange = (plus: string) => {
    this.setState((prevState) => {
      const newDay = new Date(prevState.startDate);
      if (plus === 'minus') { newDay.setDate(newDay.getDate() - 1);
      } else { newDay.setDate(newDay.getDate() + 1) }
      return { startDate: newDay }
    })
  };
  
  openDatePicker = () => {
    this.setState({ datePickerIsOpen: true })
  }
  closeDatePicker = () => {
    this.setState({ datePickerIsOpen: false })
  }
  handleBack = () => {
    this.props.navigation.history.goBack();
  }
  renderItem(item: any) {
    if (item.type === 'task') {
      const date = moment(item.attributes.due_date, 'DD/MM/YYYY')
      item.title = item.attributes.task_name
      item.start = moment.utc(date).toDate()
      item.end = moment.utc(date).toDate()
    } else {
      item.title = item.type === "appointment" ? item.attributes.title : item.attributes.job_title
      item.start = moment.utc(`${item.attributes?.start_date} ${item.attributes?.start_time}`).toDate()
      item.end = moment.utc(`${item.attributes?.end_date} ${item.attributes?.end_time}`).toDate()
      item.des = item.attributes?.description
    }
  }
  getCustomerResponse(responseJson: { errors: string; data: []; }) {
    if (responseJson && !responseJson.errors) {
      this.setState({ getCustomerData: responseJson.data })
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
        this.setState({ getCustomerData: [] })
      }
    }
  }
  getSiteResponse(responseJson: { errors: string; data: []; }) {
    if (responseJson && !responseJson.errors) {
      this.setState({ getSiteData: responseJson.data })
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
        this.setState({ getSiteData: [] })
      }
    }
  }
  getAppointmentResponse(responseJson: { errors: string; forEach: (arg0: (item: object) => object) => void; }) {
    if (responseJson && !responseJson.errors) {
      let newArray: any = []
      responseJson?.forEach((item: any) => {
        newArray.push(...item.data)
        return item.data.map((item: any) => {
          this.renderItem(item)
        });
      });
      this.setState({ events: newArray || [], getAppointmentLoading: false })
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)
        this.setState({ events: [], getAppointmentLoading: true })
      }
    }
  }
  updateAppointmentStatusResponse(responseJson: { errors: { [x: string]: [0]; }; data: { data: { attributes: { mark_as_complete: boolean; }; }; }; }) {
    if (responseJson && !responseJson.errors) {
      this.getAppointment()
      this.setState({ selectedEventData: { ...this.state.selectedEventData, mark_as_complete: responseJson.data?.data?.attributes.mark_as_complete } })
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)
        for (let key in responseJson.errors) {
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        }
      }
    }
  }
  deleteAppointmentResponse(responseJson: { errors: { [x: string]: [0]; }; message: string; }) {
    if (responseJson && !responseJson.errors) {
      this.setState({ getAppointmentLoading: false, openDeleteAppointmentModal: false })
      this.handleCloseNewAppointMentModal()
      this.handleCloseTask()
      this.handleCloseJob()
      this.getAppointment()
      this.props.openToastHandler(`${responseJson.message}`, "success")
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)
        for (let key in responseJson.errors) {
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        }
        this.setState({ events: [], getAppointmentLoading: true })
      }
    }
  }

  getEmployeeResponse(responseJson: { errors: string; data: []; }) {
    if (responseJson && !responseJson.errors) {
      responseJson.data?.forEach((item: any) => {
        item.attributes.isSelected = false
        return item
      });
      this.setState({ getEmployeeData: responseJson.data })
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
        this.setState({ getEmployeeData: [] })
      }
    }
  }
  createTaskResponse(responseJson: { errors: { [x: string]: [0]; }; data: []; }) {
    if (responseJson && !responseJson.errors) {
      this.setState({ createTaskData: responseJson.data, getAppointmentLoading: false })
      this.props.openToastHandler(`${this.props.t('Calendar.createTask')}`, "success")
      this.getAppointment()
    } else {
      if (responseJson && responseJson.errors) {
        for (let key in responseJson.errors) {
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        }
        this.setState({ createTaskData: [] })
      }
    }
  }
  createJobResponse(responseJson: { errors: { [x: string]: [0]; }; data: []; }) {
    if (responseJson && !responseJson.errors) {
      this.setState({ createJobData: responseJson.data, getAppointmentLoading: false })
      this.props.openToastHandler(`${this.props.t('jobs.toasterText.created')}`, "success")
      this.getAppointment()
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
        for (let key in responseJson.errors) {
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        }
        this.setState({ createJobData: [] })
      }
    }
  }
  updateJobResponse(responseJson: { errors: { [x: string]: [0]; }; data: []; message: string; }) {
    if (responseJson && !responseJson.errors) {
      this.setState({ updateJobData: responseJson.data, getAppointmentLoading: false })
      this.props.openToastHandler(`${responseJson.message}`, "success")
      this.getAppointment()
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
        for (let key in responseJson.errors) {
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        }
        this.setState({ updateJobData: [] })
      }
    }
  }
  updateTaskResponse(responseJson: { errors: { [x: string]: [0]; }; data: []; message: string; }) {
    if (responseJson && !responseJson.errors) {
      this.setState({ updateTaskData: responseJson.data, getAppointmentLoading: false })
      this.props.openToastHandler(`${responseJson.message}`, "success")
      this.handleCloseTask()
      this.getAppointment()
    } else {
      if (responseJson && responseJson.errors) {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation)
        for (let key in responseJson.errors) {
          this.props.openToastHandler(`${key} ${responseJson.errors[key][0]}`, "error")
        }
        this.setState({ updateTaskData: [] })
      }
    }
  }
  getCustomer = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const apiEndPoint = `${configJSON.getCustomers}?lang=${this.state.selectLanguage}`
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const getCustomerApiCall = apiCall({
      header: header,
      httpBody: null,
      url: `${apiEndPoint}`,
      httpMethod: configJSON.getAppointmentAPiMethod,
    });
    this.getCustomerApiCallId = getCustomerApiCall.messageId;
    runEngine.sendMessage(getCustomerApiCall.id, getCustomerApiCall);
  }
  getSite = (customerId: string) => {
    const token = localStorage.getItem(configJSON.storageToken)
    const apiEndPoint = `${configJSON.getSites}?customer_id=${customerId || this.state.selectedEventData.customer_id}&lang=${this.state.selectLanguage}`
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const getSiteApiCall = apiCall({
      header: header,
      httpBody: null,
      url: `${apiEndPoint}`,
      httpMethod: configJSON.getAppointmentAPiMethod,
    });
    this.getSiteApiCallId = getSiteApiCall.messageId;
    runEngine.sendMessage(getSiteApiCall.id, getSiteApiCall);
  }
  getAppointment = async () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const apiEndPoint = configJSON.appointmentAPiEndPoint
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const employeeId = this.state.getEmployeeData.filter((item: any) => item.attributes && item.attributes.isSelected).map((filItem: any) => filItem.id)
    let employeeGetAppointmentUrl
    employeeGetAppointmentUrl = `${apiEndPoint}?employee_id=${this.state.employee_id}?lang=${this.state.selectLanguage}`
    let getAppointmentUrl
    if (employeeId.length === 0) {
      getAppointmentUrl = `${apiEndPoint}?lang=${this.state.selectLanguage}`
    } else {
      getAppointmentUrl = `${apiEndPoint}?employee_id=${employeeId}?lang=${this.state.selectLanguage}`
    }
    const getAppointmentApiCall = apiCall({
      header: header,
      httpBody: null,
      url: !this.state.isEmployee ? getAppointmentUrl : employeeGetAppointmentUrl,
      httpMethod: configJSON.getAppointmentAPiMethod,
    });
    this.getAppointmentApiCallId = getAppointmentApiCall.messageId;
    runEngine.sendMessage(getAppointmentApiCall.id, getAppointmentApiCall);
  }
  updateAppointmentStatus = async (checkboxEvent: string) => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    let url
    if (this.state.selectedEventData.type === 'appointment') {
      url = `${configJSON.appointmentAPiEndPoint}/${this.state.selectedEventData.id}?lang=${this.state.selectLanguage}`
    } else {
      url = `${configJSON.taskApiEndPoint}/${this.state.selectedEventData.id}?lang=${this.state.selectLanguage}`
    }
    let updateAppointmentStatusBody = new FormData();
    updateAppointmentStatusBody.append("data[mark_as_complete]", checkboxEvent);
    const updateAppointmentApiCall = apiCall({
      header: header,
      httpBody: updateAppointmentStatusBody,
      url: url,
      httpMethod: configJSON.updateAppointmentAPiMethod
    });
    this.updateAppointmentStatusApiCallId = updateAppointmentApiCall.messageId;
    runEngine.sendMessage(updateAppointmentApiCall.id, updateAppointmentApiCall);
  }
  deleteAppointment = async () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    let url
    if (this.state.selectedEventData.type === 'appointment') {
      url = `${configJSON.appointmentAPiEndPoint}/${this.state.selectedEventData.id}?lang=${this.state.selectLanguage}`
    } else if (this.state.selectedEventData.type === 'job') {
      url = `${configJSON.jobApiEndPoint}/${this.state.selectedEventData.id}?lang=${this.state.selectLanguage}`
    } else {
      url = `${configJSON.taskApiEndPoint}/${this.state.selectedEventData.id}?lang=${this.state.selectLanguage}`
    }
    this.setState({ getAppointmentLoading: true })
    const deleteAppointmentApiCall = apiCall({
      header: header,
      httpBody: null,
      url: url,
      httpMethod: configJSON.deleteAllAppointmentsAPiMethod,
    });
    this.deleteAppointmentApiCallId = deleteAppointmentApiCall.messageId;
    runEngine.sendMessage(deleteAppointmentApiCall.id, deleteAppointmentApiCall);
  }
  getEmployee = async () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const apiEndPoint = configJSON.getEmployeeApiEndPoint
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const getEmployeeApiCall = apiCall({
      header: header,
      httpBody: null,
      url: `${apiEndPoint}`,
      httpMethod: configJSON.getAppointmentAPiMethod,
    });
    this.getEmployeeApiCallId = getEmployeeApiCall.messageId;
    runEngine.sendMessage(getEmployeeApiCall.id, getEmployeeApiCall);
  }
  createTask = async (values: CreateTask) => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    this.setState({ getAppointmentLoading: true })
    let createTaskBody = new FormData();
    createTaskBody.append("data[task_name]", values.title);
    createTaskBody.append("data[employee_id]", values.employee_id);
    createTaskBody.append("data[due_date]", values.due_date);
    createTaskBody.append("data[urgency]", values.urgency);
    createTaskBody.append("data[email_team]", values.email_team);
    const createTaskApiCall = apiCall({
      header: header,
      httpBody: createTaskBody,
      url: `${configJSON.taskApiEndPoint}?lang=${this.state.selectLanguage}`,
      httpMethod: configJSON.createAppointmentAPiMethod
    });
    this.createTaskApiCallId = createTaskApiCall.messageId;
    runEngine.sendMessage(createTaskApiCall.id, createTaskApiCall);
  }
  updateTask = async (values: CreateTask) => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    this.setState({ getAppointmentLoading: true })
    let updateTaskBody = new FormData();
    updateTaskBody.append("data[task_name]", values.title);
    updateTaskBody.append("data[employee_id]", values.employee_id);
    updateTaskBody.append("data[due_date]", values.due_date);
    updateTaskBody.append("data[email_team]", values.email_team);
    if (this.state.selectedEventData.urgency !== values.urgency) {
      updateTaskBody.append("data[urgency]", values.urgency)
    }
    const updateTaskApiCall = apiCall({
      header: header,
      httpBody: updateTaskBody,
      url: `${configJSON.taskApiEndPoint}/${this.state.selectedEventData.id}?lang=${this.state.selectLanguage}`,
      httpMethod: configJSON.updateTaskAPiMethod
    });
    this.updateTaskApiCallId = updateTaskApiCall.messageId;
    runEngine.sendMessage(updateTaskApiCall.id, updateTaskApiCall);
  }
  createJob = async (values: CreateJob) => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    this.setState({ getAppointmentLoading: true })
    let createJobBody = new FormData();
    createJobBody.append("data[job_title]", values.title);
    createJobBody.append("data[description]", values.description || '');
    createJobBody.append("data[start_date]", moment(new Date(values.start_date)).format('YYYY-MM-DD'));
    createJobBody.append("data[end_date]", moment(new Date(values.end_date)).format('YYYY-MM-DD'));
    createJobBody.append("data[start_time]", values.start_time);
    createJobBody.append("data[end_time]", values.end_time);
    createJobBody.append("data[customer_id]", values.customer_id);
    createJobBody.append("data[site_id]", values.site_id);
    createJobBody.append("data[employee_ids]", values.employee_id);
    createJobBody.append("data[all_day]", values.all_day);
    const createJobApiCall = apiCall({
      header: header,
      httpBody: createJobBody,
      url: `${configJSON.jobApiEndPoint}?lang=${this.state.selectLanguage}`,
      httpMethod: configJSON.createAppointmentAPiMethod
    });
    this.createJobApiCallId = createJobApiCall.messageId;
    runEngine.sendMessage(createJobApiCall.id, createJobApiCall);
  }
  updateJob = async (values: CreateJob) => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    this.setState({ getAppointmentLoading: true })
    let updateJobBody = new FormData();
    updateJobBody.append("data[job_title]", values.title);
    updateJobBody.append("data[description]", values.description || '');
    updateJobBody.append("data[start_date]", moment(new Date(values.start_date)).format('YYYY-MM-DD'));
    updateJobBody.append("data[end_date]", moment(new Date(values.end_date)).format('YYYY-MM-DD'));
    updateJobBody.append("data[start_time]", values.start_time);
    updateJobBody.append("data[end_time]", values.end_time);
    updateJobBody.append("data[customer_id]", values.customer_id);
    updateJobBody.append("data[site_id]", values.site_id);
    updateJobBody.append("data[employee_ids]", values.employee_id);
    updateJobBody.append("data[all_day]", values.all_day);
    const updateJobApiCall = apiCall({
      header: header,
      httpBody: updateJobBody,
      url: `${configJSON.jobApiEndPoint}/${this.state.selectedEventData.id}?lang=${this.state.selectLanguage}`,
      httpMethod: configJSON.updateTaskAPiMethod
    });
    this.updateJobApiCallId = updateJobApiCall.messageId;
    runEngine.sendMessage(updateJobApiCall.id, updateJobApiCall);
  }


  handleProjectDetailModalClose = () => {
    this.setState({
      projectDetailModal: false
    })
  }

  handleProjectDetailModalOpen = (event: any) => {
    this.setState({
      projectDetailModal: true,
      selectedProjectDate: event
    })
    console.log(this.filterDataByDateAndType(this.state.events, event, "job"))
  }

  formatDate = (inputDate: string) => {
    const dateObject = new Date(inputDate);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  filterDataByDateAndType = (data: any, targetDate: any, targetType: any) => {
    const formattedDate = this.formatDate(targetDate);

    return data.filter((item: any) => {
      const dateStr = item.attributes.due_date;
      const convertedDate = moment(dateStr, 'DD/MM/YYYY').format('YYYY-MM-DD');
      return this.formatDate(item.attributes.start_date||convertedDate) === formattedDate && item.type === targetType;
    });
  }

  markAsCompleteColor = (complete: string) => {
    return complete === "completed" ? "#50AF18" : "#ED9134"
  }

  markAsCompleteLine = (complete: string) => {
    return complete === "completed" ? "line-through" : "none"
  }

  formatTime = (time: string) => {
    const dateObject = new Date(time);
    const day = dateObject.getFullYear();
    const year = String(dateObject.getMonth() + 1).padStart(2, '0');
    const month = String(dateObject.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

}

// Customizable Area End