import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Popover,
  withWidth
} from "@material-ui/core";

import { dialogBoxIcon ,userIcon,arrowLeft} from "./assets"
import {
  withStyles
} from "@material-ui/core/styles";
import { withSnackbar } from "../../../components/src/toastweb"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { StyledCheckbox } from "../../JobListing2/src/JobsUtility.web"
import DashboardNotificationsController, { Props , NotifyDataItem,GroupedItems,ComponentClasses} from "./DashboardNotificationsController"
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import moment from "moment";
import {convertToArabicNumerals} from "../../CustomisableUserProfiles/src/utility.web";
import { withTranslation } from "react-i18next";
import ConfirmationModal from "../../../components/src/ConfirmationModal";
// Customizable Area End


export class DashboardNotifications extends DashboardNotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  formatDateTime = (dateTime: string) => {

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Start of today

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0); // Start of yesterday

    const date = new Date(dateTime);

    return this.findToday(date, today, yesterday);
  }

  findToday = (date: Date, today: Date, yesterday: Date) => {
    const {t} = this.props;
    if (date.toDateString() === today.toDateString()) {
      return this.state.lang === "en" ? 'Today' : t("notifications.today");
    } else if (date.toDateString() === yesterday.toDateString()) {
      return this.state.lang === "en" ? 'Yesterday' : t("notifications.yesterday");
    } else {
      const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short' };
      const formattedDate: string = this.state.lang === "en" ?
        date.toLocaleDateString('en-US', options) : date.toLocaleDateString('ar-SA', options);;
      const parts: string[] = formattedDate.split(' ');
      parts.reverse(); // Reverse the parts array
      return parts.join(' ');
    }
  }

  renderDate = (item: NotifyDataItem, classes: ComponentClasses) => {
    const {t} =  this.props;
    return item.attributes?.notificationDetails.title.length > this.props.sliceString ?
      <>
        <span > {item.attributes?.notificationDetails.title.slice(0, this.props.sliceString)}...</span>
        <label
          data-test-id="read-more"
          onClick={this.toggleReadMore.bind(this, item.attributes.id)}
          className={`${classes.dashReadLess} ${classes.ReadLess}`}>
            {t("notifications.more")}
            </label>
      </>
      :
      item.attributes?.notificationDetails.title
  }

  findIdInArrTwo = (notify:NotifyDataItem,item: number) => notify.attributes.id == item

  renderData = (classes: ComponentClasses, item: NotifyDataItem) => {
    const { expandedTitles } = this.state;
    const {t} = this.props;
    return (

      <Box data-test-id="redirection"
        className={`${classes.notifyContainer} ${classes.dashnotifyContainer}`} onClick={this.goToRedirect.bind(this, item)}>

        <Box>
          <StyledCheckbox
            data-test-id="checkbox"
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={this.addNotifyIds.bind(this, item)}
            onClick={(event: React.MouseEvent<HTMLInputElement>) => event.stopPropagation()}
            checked={
              this.state.notifyIds.some(this.findIdInArrTwo.bind(this, item))
            }

          />
          
        </Box>
        
        <Box className={`${classes.notifyImageParent} ${classes.dashnotifyImageParent}`}>
          {!item.attributes?.is_read &&
            <FiberManualRecordIcon className=
              {this.state.lang === "en" ? `${classes.badgeIcon} ${classes.dashbadgeIcon}` :
                `${classes.badgeIconAr} ${classes.dashbadgeIconAr}`
              }
         />
         } 
         
          <img src={item.attributes?.account_detail?.profile_image || userIcon} alt="profile_image"
            className={`${classes.imgStyles} ${classes.dashimgStyles}`} />
        </Box>
        <Box className={`${classes.notifyBodyContainer} ${classes.dashnotifyBodyContainer}`}>
          <Box className={`${classes.notifyBodyParent} ${classes.dashnotifyBodyParent}`}>

            <Typography variant="body2"
              className={`${classes.notifyTitle} ${classes.dashnotifyTitle}`}>
              {item.attributes?.notificationDetails?.heading || "--"}

            </Typography>
            <div
              data-test-id="more-action-open"
              aria-describedby={this.state.anchorId}
              onClick={(event) => this.handleClick(item, event)} // Attach the event handler to the wrapper div
              className={`${classes.hideSmall} ${classes.dashhideSmall} ${classes.dashMoreIconxs} ${classes.MoreIconxs}`}
            >
              <MoreHorizIcon />
            </div>
        
          </Box>

          <Box className={`${classes.notifyBodyParent} ${classes.dashnotifyBodyParent}`}>

            <Typography variant="caption"
              className={`${classes.notifyBody} ${classes.notifyTitleWidth}
              ${classes.dashnotifyBody} ${classes.dashnotifyTitleWidth}`}>
              {expandedTitles.includes(item.attributes?.id) ?
                <>
                  <span >{item.attributes?.notificationDetails.title}</span>

                  <label
                    data-test-id="read-less"
                    onClick={this.toggleReadLess.bind(this, item.attributes.id)}
                    className={`${classes.dashReadLess} ${classes.ReadLess}`}>
                      {t("notifications.less")}
                  </label>
                </>
                :
                this.renderDate(item, classes)
              }

            </Typography>
            <Typography align="right" className={`${classes.notifyBody} ${classes.dashnotifyBody}`}
              variant="caption"
            >
              {moment(item.attributes?.created_at).fromNow()}</Typography>
            <div
              data-test-id="more-action-open" aria-describedby={this.state.anchorId}
              onClick={this.handleClick.bind(this, item)} className={`${classes.hideLarge} 
              ${classes.dashhideLarge} ${classes.dashMoreIconxs} ${classes.MoreIconxs}`}
            >
              <MoreHorizIcon />
            </div>
           
          </Box>
        </Box>
      </Box>

    )
  }
  renderItems = (items: NotifyDataItem[], classes: ComponentClasses) => {
    return items && items.length > 0 && items.map((item: NotifyDataItem, index: number) => (
      <div key={item.id}>
        {this.renderData(classes, item)}
      </div>
    ))
  }

  renderIndexZero = (index: number, classes: ComponentClasses) => {
    const {t} = this.props
    return index === 0 &&
      <Box className={this.state.lang === "en" ?
        `${classes.markReadParent} ${classes.dashmarkReadParent}` :
        `${classes.markReadParentAr} ${classes.dashmarkReadParentAr}`}>
        <Typography data-test-id="view-notifications" variant="body1"
          onClick={this.viewAllNotifications.bind(this, "SELECTEDDELETE")}
          className={

            `${classes.markRead} ${classes.dashmarkRead} ${classes.deleteText} ${classes.dashdeleteText}`

          }
        >
          {
            this.state.notifyIds.length === 0 ? t("notifications.deleteAll") : t("notifications.delete")}
        </Typography>
        <Typography variant="body1" data-test-id="markAsRead"
          className={`${classes.markRead} ${classes.dashmarkRead}`}
          onClick=
          {this.markNotification.bind(this, "MARK_ALL")}>
          {this.state.notifyIds.length === 0 ? t("notifications.markAllRead") : t("notifications.markAsRead")}
        </Typography>
        {this.state.notifyIds.length !== 0 && <Typography data-test-id="select-all" variant="body1" onClick={this.selectAll}
          className={`${classes.markRead} ${classes.dashmarkRead}`}>
          {this.state.areSelected ? t("notifications.unselectAll") : t("notifications.selectAll")}
        </Typography>}

      </Box>
  }
  renderEntries = (groupedItems:GroupedItems, classes: ComponentClasses) => {
    return (
      Object.entries(groupedItems).map(([date, items]:[string, NotifyDataItem[]], index: number) => (
        <Box key={date} width={"100%"} >
          <Box sx={{ mb: "1rem" }} width={"100%"} display={"flex"} flexDirection={"row"} alignItems={"center"}>
           <Box>
           <Typography variant="body1" className={`${classes.todayDate} ${classes.dashtodayDate}`}>
              {this.formatDateTime(date)}
            </Typography>
           </Box>
            {
              this.renderIndexZero(index,classes)
            }
          </Box>
          {this.renderItems(items,classes)}
          {index !== Object.entries(groupedItems).length - 1 &&
            <hr className={`${classes.dashHr} ${classes.Hr}`} />} {/* Add hr except for the last entry */}

        </Box>
      ))
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes , t, width}: any = this.props;
    const isMobile = ['xs'].includes(width);
    const {open,title,content,cancel,leave,handleLeave} = this.state.confirmationModal

    return (

      <>
        <ConfirmationModal
          open={open}
          title={title}
          content={content}
          cancel={cancel}
          leave={leave}
          handleCancel={this.handleCloseConfirmationModal}
          handleLeave={handleLeave}
          languageSelected={this.state.lang}
          isMobile={isMobile}
        />
        <Popover
          id={this.state.anchorId}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          data-test-id="more-action-close"
          onClose={this.handleClose}

          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >

          <Grid container direction="column"
            className={this.state.lang == "en" ?
              `${classes.smallPopup} ${classes.dashsmallPopup}` : `${classes.smallPopupAr} ${classes.dashsmallPopup}`}>
            <Grid item className={`${classes.popoverItems} ${classes.dashpopoverItems}`} >
              <CheckIcon className={this.state.is_read ? `${classes.unreadColor} ${classes.dashUnreadColor}` : `${classes.dashCheckIcon} ${classes.CheckIcon}`} />
              <Typography data-test-id="singleMark" variant="caption"
                className={this.state.is_read ? `${classes.dashMarkUnreadColor} ${classes.MarkUnreadColor}` : `${classes.dashMarkReadColor} ${classes.MarkReadColor}`}
                onClick={this.markNotification.bind(this, "SINGLEMARK")} >
                {this.state.is_read ? t("notifications.markAsUnread") : t("notifications.markAsRead")}
              </Typography>
            </Grid>
            <hr />
            <Grid item className={`${classes.popoverItems} ${classes.dashpopoverItems}`}>
              <DeleteIcon className={`${classes.dashDeleteIcon} ${classes.DeleteIcon}`} />
              <Typography data-test-d="singleDelete" variant="caption"
                onClick={this.viewAllNotifications.bind(this, "SINGLEDELETE")}
                className={`${classes.dashDeleteColor} ${classes.DeleteColor}`} >
                {t("notifications.delete")}
              </Typography>
            </Grid>
          </Grid>
        </Popover>
        {this.props.diaLogBox &&
          <img
            className={
              this.state.lang === "en" ?
                `${classes.dialogBoxIcon} ${classes.dashdialogBoxIcon}` :
                `${classes.dialogBoxIcon} ${classes.dashdialogBoxIconAr}`
            } src={dialogBoxIcon} alt="" />
        }
        <Box id = "scrollableDiv" 
          className={
            this.state.lang === "en" ?
              `${classes.createPopup} ${classes.dashcreatePopup}` :
              `${classes.createPopup} ${classes.dashcreatePopupAr}`
          }
        >
          <Box display={"flex"} gridGap={"0.5rem"} className={`${classes.notifyHeadingBox} ${classes.dashnotifyHeadingBox}`}>
          <img style={webStyle.arrowLeft} src={arrowLeft} alt="Icon" data-test-id="back-button" onClick={this.handleBackNavigate} />
            <Typography
              variant="subtitle2" className={`${classes.notifyheading} ${classes.dashnotifyheading}`}>
                {t("notifications.notifications")}
            </Typography>
            <Typography variant="caption"

              className={`${classes.notifyCount} ${classes.dashnotifyCount}`}>
              {this.state.lang === "en"
                ? this.state.notification_count : convertToArabicNumerals(this.state.notification_count - 1)} </Typography>
          </Box>
          {this.state.notifyData.length > 0 ?
            this.renderEntries(this.state.groupedItems, classes)
            :
            !this.state.loading && this.state.notifyData && this.state.notifyData.length === 0 &&
            <Typography variant="subtitle1" 
            className={`${classes.noNotifyText} ${classes.dashnoNotifyText}`} >
              {t("customers.noDataFound")}
            </Typography>
          }

          <Box display={"flex"} alignSelf={"flex-end"} mt={"auto"}>
            <Typography data-test-id="view-notifications" variant="body1"
              onClick={this.handleNavigation}
              className={
                `${classes.markRead} ${classes.dashmarkRead} `
              }
            >
              {this.props.diaLogBox &&
                t("notifications.viewAll")}
            </Typography>
          </Box>
         

        </Box>
        <LoadingBackdrop
          loading={
            this.state.loading
          }
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = (theme: any) => ({
  boxContainerNotification: {
    [theme.breakpoints.down("md")]: {
      padding: "0rem !important"
    }
  },

});
const webStyle = {
 
  arrowLeft: {
    height:"25px",
    padding:"0 10px 0 10px"
  }
}

const DashboardNotificationStyles = withTranslation()(withSnackbar(withStyles(styles)(withWidth()(DashboardNotifications))));
export default DashboardNotificationStyles
// Customizable Area End
