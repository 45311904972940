import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { CONSTANTS } from "../../../components/src/theme";
import {
  dropdownRequests,
  dropdownQuotes,
  dropdownJobs,
  dropdownInvoices
} from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";

const mockDashboardCard = [
  {
    id: 1,
    title: "Requests",
    count: "",
    percentage: "",
    description: "",
  },
  {
    id: 2,
    title: "Jobs",
    count: "",
    percentage: "",
    description: "",
  },
  {
    id: 3,
    title: "Revenue",
    count: "",
    percentage: "",
    description: "",
  },
]

const mockStatusData = [
  {
    id: 1,
    text: "Requests",
    color: CONSTANTS.color.darkBlue,
    backgroundColor: CONSTANTS.color.softPurple,
    icon: dropdownRequests,
    statusData: []
  },
  {
    id: 2,
    text: "Quotations",
    color: CONSTANTS.color.vividOrange,
    backgroundColor: CONSTANTS.color.paleApricot,
    icon: dropdownQuotes,
    statusData: []
  },
  {
    id: 3,
    text: "Jobs",
    color: CONSTANTS.color.magentaColor,
    backgroundColor: CONSTANTS.color.lightPastelPink,
    icon: dropdownJobs,
    statusData: []
  },
  {
    id: 4,
    text: "Invoices",
    color: CONSTANTS.color.deepOrange,
    backgroundColor: CONSTANTS.color.softOrange,
    icon: dropdownInvoices,
    statusData: []
  },
]

const mockGraphData = {
  labels: [],
  datasets: [{
    data: [],
    backgroundColor: CONSTANTS.color.darkBlue,
    borderColor: CONSTANTS.color.darkBlue,
    tension: 0.5,
    fill: false,
  }],
}

const mockOptionsData = {
  plugins: {
    legend: false,
  },
  scales: {
    x: {
      grid: {
        display: true,
      }
    },
    yAxes: {
      min: 0,
      grid: {
        display: true
      }
    },
  }
}

interface ResponseJson {
  data: {
    requests: {
      number_of_requests: number;
      compared_to_last: string;
      status: string;
    },
    jobs: {
      number_of_jobs: number;
      compared_to_last: string;
      status: string;
    },
    revenue: {
      number_of_revenue: number;
      compared_to_last: string;
      status: string;
    }
  },
}
interface ItemType {
  title: string;
  count: number | string;
  percentage: string;
  description: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  permissions:any
  openToastHandler: Function;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  title: string;
  mockCalendarStatus: string;
  barDate: string;
  dashboardCard: ItemType[];
  statusData: any;
  graphData: any;
  options: any;
  selectedDate: any;
  dashboardData: any;
  dashboardLoading: boolean;
  center: {
    lat: number;
    lng: number;
  };
  markerPlaces: string[];
  selectedStatus: Record<string, boolean>;
  languageSelected: string;
  status: string;
  dateModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  datePickerRef: any;
  mapRef: any;
  getDashboardDataApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      title: "Requests",
      mockCalendarStatus: "Weekly",
      barDate: "",
      dashboardCard: mockDashboardCard,
      statusData: mockStatusData,
      graphData: mockGraphData,
      options: mockOptionsData,
      selectedDate: {
        startDate: new Date(),
        endDate: null
      },
      dashboardData: [],
      dashboardLoading: false, 
      center: { lat: 25.2770048, lng: 55.2936751 },
      markerPlaces: [],
      selectedStatus: {1: false, 2: false, 3: false, 4: false},
      languageSelected: "",
      status: "Today",
      dateModal: false,
    };
    this.datePickerRef = React.createRef<any>();
    this.mapRef = React.createRef<any>();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });

    if(this.state.languageSelected === "ar") {
      this.setState({ mockCalendarStatus: "أسبوعي", title: "طلبات", status: "اليوم" });
    }
    
    this.getDashboardData();
    this.setState({ dashboardLoading: true });

    window.scrollTo(0, 0);
    this.handleShowStatus();
    if (!localStorage.getItem("authToken")) {
      localStorage.clear();
      this.props.navigation.history?.goBack();
    }
    // Customizable Area End
  }

  // Customizable Area Start  
  errorHandlerMessage = async (error: string) => {
    const { token } = (error as {token?: string}) || {};
    if (token === configJSON.tokenExpired || token === configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel);
      this.tokenExpired();
    }
  }

  errorResponseMessage = (error: string) => {
    error && this.errorHandlerMessage(error);
  }

  tokenExpired = () => {
    setTimeout(this.setTimeOutHandler, 2000);
  }

  setTimeOutHandler = () => {
    localStorage.clear();
    this.props.navigation.history?.push("/Login");
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getDashboardDataApiCallId) {
        if (!responseJson.errors) {
          this.setState({ dashboardLoading: false });
          this.setState({ dashboardData: responseJson });
          const updatedStatusData = this.state.statusData?.map((entry: { text: string }, index: number) => {
            const { t } = this.props;
            const titles = [
              t("dashboard.requests"),
              t("dashboard.quotations"),
              t("dashboard.jobs"),
              t("dashboard.invoices")
            ]
            const title = [
              t("dashboard.Requests"),
              t("dashboard.Quotations"),
              t("dashboard.Jobs"),
              t("dashboard.Invoices")
            ]
            const key = entry?.text?.toLowerCase();
            const responseData = responseJson.status?.[key] || []
            return {
              ...entry,
              title: titles[index],
              statusData: responseData,
              titles:title[index]
            };
          });
          const { t } = this.props;
          if(this.state.status === t('dashboard.custom')) {
            this.setState({
              selectedDate: {
                ...this.state.selectedDate,
                startDate: new Date(responseJson.start_date),
                endDate: new Date(responseJson.end_date)
              }
            });
          }
          this.handleCardData(responseJson);
          this.handleGraphData();
          this.setState({
            barDate: responseJson.bar_date,
            statusData: updatedStatusData,
            markerPlaces: responseJson.map,
          });
          this.fitMarkers();
        } else if (responseJson.errors) {
          this.setState({ dashboardLoading: false });
          const error = responseJson?.errors?.[0];
          this.errorResponseMessage(error);
        } else {
          //Check Error Response
          this.setState({ dashboardLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCardData = async (responseJson: ResponseJson) => {
    const langugageSelected = await getStorageData("lang");
    const getCount = this.state.dashboardCard.map((item: ItemType) => {
      if(item.title === "Requests" || item.title === "طلبات") {
        item.title = langugageSelected === "ar" ? "طلبات" : "Requests";
        item.count = responseJson.data?.requests?.number_of_requests
        item.percentage = responseJson.data?.requests?.compared_to_last
        item.description = responseJson.data?.requests?.status
      } else if (item.title === "Jobs" || item.title === "وظائف") {
        item.title = langugageSelected === "ar" ? "وظائف" : "Jobs";
        item.count = responseJson.data?.jobs?.number_of_jobs
        item.percentage = responseJson.data?.jobs?.compared_to_last
        item.description = responseJson.data?.jobs?.status
      } else if (item.title === "Revenue" || item.title === "ربح") {
        item.title = langugageSelected === "ar" ? "ربح" : "Revenue";
        item.count = responseJson.data?.revenue?.number_of_revenue
        item.percentage = responseJson.data?.revenue?.compared_to_last
        item.description = responseJson.data?.revenue?.status
      }
      return item;
    });
    this.setState({ dashboardCard: getCount });
  }

  fitMarkers = () => {
    const map = this.mapRef.current;
    if (map && this.state.markerPlaces?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      this.state.markerPlaces.forEach((marker: any) => {
        bounds.extend(new window.google.maps.LatLng(marker?.latitude, marker?.longitude));
      });

      map.fitBounds(bounds);
    }
  };
  mapLoad = (map: google.maps.Map) => {
    this.mapRef.current=map;
    const zoomInButtonn = document.createElement('button');
    zoomInButtonn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#1c18af"/>
        <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z" fill="#1c18af"/>
    </svg>`;
    zoomInButtonn.style.backgroundColor = 'white';
    zoomInButtonn.style.marginTop = '10px';
    zoomInButtonn.style.marginBottom = '5px';
    zoomInButtonn.style.padding = '6px 3px 0px 5px';
    zoomInButtonn.style.borderRadius = '4px';
    zoomInButtonn.style.border = 'none';
    zoomInButtonn.style.paddingLeft = '5px';
    zoomInButtonn.style.cursor = 'pointer';
    zoomInButtonn.title = 'Zoom In';
    zoomInButtonn.addEventListener('click', () => map?.setZoom((map?.getZoom() ?? 0) + 1));
    const zoomOutButtone = document.createElement('button');
    zoomOutButtone.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 24 24" width="30">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path fill="#1c18af" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"/>
    </svg>`;
    zoomOutButtone.style.backgroundColor = 'white';
    zoomOutButtone.style.marginBottom = '5px';
    zoomOutButtone.style.padding = '6px 3px 0px 5px';
    zoomOutButtone.style.borderRadius = '4px';
    zoomOutButtone.style.paddingLeft = '5px';
    zoomOutButtone.style.border = 'none';
    zoomOutButtone.style.cursor = 'pointer';
    zoomOutButtone.title = 'Zoom Out';
  
    zoomOutButtone.addEventListener('click', () =>   map?.setZoom((map?.getZoom() ?? 0) - 1));
  
    const controlDive = document.createElement('div');
    controlDive.style.position = 'absolute';
    controlDive.style.marginRight = '20px';
    controlDive.style.marginBottom = '20px';
    controlDive.style.bottom = '100px';
    controlDive.style.right = '10px';
    controlDive.style.display = 'flex';
    controlDive.style.flexDirection = 'column';
    controlDive.style.zIndex = '1';
    controlDive.style.justifyContent = 'space-between'; 
    controlDive.appendChild(zoomInButtonn);
    controlDive.appendChild(zoomOutButtone);
  
    const controlPositione = google.maps.ControlPosition.RIGHT_BOTTOM;
    map.controls[controlPositione].push(controlDive);
  }; 
  handleGraphData = () => {
    const dataKey = (this.state.title === "Requests" || this.state.title === "طلبات")
    || (this.state.title === "Jobs" || this.state.title === "وظائف")
    || (this.state.title === "Revenue" || this.state.title === "ربح")
    ? "graph"
    : null;

    if (dataKey) {
      const dataToUpdate = this.state.dashboardData?.[dataKey]?.data;
      const labelDates = this.state.dashboardData?.[dataKey]?.date;

      this.setState({
        graphData: {
          ...this.state.graphData,
          labels: labelDates,
          datasets: [{
            ...this.state.graphData.datasets[0],
            data: dataToUpdate
          }]
        }
      });

      const currentState = { ...this.state };
      const newBackgroundColor = (this.state.title !== "Requests" && this.state.title !== "طلبات") ? CONSTANTS.color.goldenApricot : CONSTANTS.color.darkBlue;
      const newBorderColor = (this.state.title !== "Requests" && this.state.title !== "طلبات") ? CONSTANTS.color.goldenApricot : CONSTANTS.color.darkBlue;

      currentState.graphData.datasets[0].backgroundColor = newBackgroundColor;
      currentState.graphData.datasets[0].borderColor = newBorderColor;

      this.setState({ graphData: currentState.graphData });
    }
  }

  handleChangeTitle = (item: { title: string }) => {
    this.setState({ title: item.title }, () => {
      this.handleGraphData();
      this.getDashboardData();
      this.setState({ dashboardLoading: true });
    });
  }

  handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    this.setState({
      selectedDate: { ...this.state.selectedDate, startDate: start, endDate: end },
    }, () => {
      if(start && end) {
        this.getDashboardData();
        this.setState({ dashboardLoading: true });
        this.setState({ dateModal: false });
      }
    });
  };

  handleDateModalOpen = () => {
    this.setState({ dateModal: !this.state.dateModal });
  }

  handleStatusModalClose = () => {
    const { t } = this.props;
    if(this.state.status !== t('dashboard.custom')) {
      this.setState({ dateModal: false });
    }
  }

  handleSelectStatus = (item: { title: string; }) => {
    const { t } = this.props;
    const { startDate, endDate } = this.state.selectedDate;
    this.setState({ status: item?.title }, () => {
      if(item?.title === t('dashboard.custom')) {
        if(startDate && endDate) {
          this.getDashboardData();
          this.setState({ dashboardLoading: true });
        }
      } else {
        this.getDashboardData();
        this.setState({ dashboardLoading: true });
      }
    });
    if(item?.title !== t('dashboard.custom')) {
      this.setState({ dateModal: false });
    }
  }

  handleClickStatus = (id: number, item: any) => {
    const title = item.titles
    this.props.navigation.history.push(title)

    if(window.innerWidth <= 600) {
      this.setState((prevState) => {
        const selectedStatus = {
          ...prevState.selectedStatus,
          [id]: !prevState.selectedStatus[id]
        };
        
        return {
          selectedStatus,
        };
      });
    }
  }

  handleNavigate = (i: any, item:any) => {
    const title = item.titles
    if(title && i.status){
      this.props.navigation.history.push(title, {tag: i.status})
    }
  }

  handleShowStatus = () => {
    if(window.innerWidth > 600) {
      this.setState({
        selectedStatus: {1: true, 2: true, 3: true, 4: true}
      });
    }
  }

  statusAr = (status: string) => {
    let statusAr;
    if(status === "اليوم") {
      statusAr = "today";
    } else if(status === "هذا الاسبوع") {
      statusAr = "this_week";
    } else if(status === "هذا الشهر") {
      statusAr = "this_month";
    } else if(status === "هذا العام") {
      statusAr = "this_year";
    } else if(status === "مخصص") {
      statusAr = "custom";
    }
    return statusAr;
  }

  getDashboardData = async () => {
    const { title, status, languageSelected } = this.state;
    const { startDate, endDate } = this.state.selectedDate;
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDashboardDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/dashboards?start_date=${startDate}&end_date=${endDate}&date_filter=${languageSelected === "ar" ? this.statusAr(status) : status.toLowerCase().replace(/ /g, "_")}&module=${title.toLowerCase()}&lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
}
// Customizable Area End